<template>
  <LayoutMaster>
    <template #content> <router-view></router-view></template>
  </LayoutMaster>
</template>

<script>
import LayoutMaster from "./layouts/LayoutMaster.vue";

export default {
  props: {},

  components: { LayoutMaster },

  data() {
    return {};
  },
  computed: {
    isSite() {
      return (
        this.$route.name == "loginSite" ||
        this.$route.name == "homeSite" ||
        this.$route.name == "compartilhamentosSite" ||
        this.$route.name == "gruposSite" ||
        this.$route.name == "usuariosSite"
      );
    },
    isAdmin() {
      return (
        this.$route.name == "loginAdmin" ||
        this.$route.name == "homeAdmin" ||
        this.$route.name == "planosAdmin" ||
        this.$route.name == "clientesAdmin" ||
        this.$route.name == "gruposAdmin" ||
        this.$route.name == "usuariosAdmin"
      );
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style>
/*
body {
  background: #e9ecee;
}
btn:focus,
a:focus,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}*/
cursor-pointer {
  cursor: pointer;
}
</style>
