<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="
        container-fluid
        d-flex
        flex-column flex-md-row
        align-items-center
        justify-content-between
      "
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">2022©</span>
        <a href="/" target="_blank" class="text-gray-800 text-hover-primary"
          >Enuve Gestão de Arquivos Digitais S/A</a
        >
      </div>
      <!--end::Copyright-->
      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a href="/" target="_blank" class="menu-link px-2">Sobre</a>
        </li>
        <li class="menu-item">
          <a href="/" target="_blank" class="menu-link px-2">Suporte</a>
        </li>
        <li class="menu-item">
          <a href="/" target="_blank" class="menu-link px-2">Preços</a>
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script>
export default {
  props: {},

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style>
</style>
