<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormPlano'"
        ref="dialogFormPlano"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div class="d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
          </div>
        </div>

        <div>
          <div v-if="item._id == ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Novo plano</h2>
          </div>

          <div v-if="item._id !== ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Editando plano</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <div
            class="fv-row mb-7 text-center"
            :class="{ 'd-none': !confirmar }"
          >
            <h4 class="text-primary">
              <strong>Confirmar a alteração do plano ?</strong>
            </h4>
            <h4 class="text-gray-700">
              <strong
                >Todos os clientes associados ao plano serão afetados</strong
              >
            </h4>
          </div>

          <div :class="{ 'd-none': confirmar }">
            <div>
              <div class="fv-row mb-7">
                <label class="fs-6 fw-bold mb-2">Nome</label>

                <input
                  v-model="item.nome"
                  class="form-control form-control-solid"
                  :class="{ 'is-invalid': itemErros.nome.status }"
                  type="text"
                  @keyup="enterSalvar"
                  autofocus="true"
                  ref="nome"
                  autocomplete="off"
                />
                <div v-if="itemErros.nome.status" class="invalid-feedback">
                  {{ itemErros.nome.mensagem }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <label class="fs-6 fw-bold mb-2">Usuários</label>
                  <input
                    v-model="item.usuariosTotal"
                    class="form-control form-control-solid"
                    :class="{ 'is-invalid': itemErros.usuariosTotal.status }"
                    type="number"
                    min="1"
                    placeholder="1"
                    @keypress="$funcoes.keyPressInteiro"
                    @keyup="enterSalvar"
                  />
                  <div
                    v-if="itemErros.usuariosTotal.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.usuariosTotal.mensagem }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <label class="fs-6 fw-bold mb-2">Espaço total (GB)</label>
                  <input
                    v-model="item.espacoTotal"
                    class="form-control form-control-solid"
                    :class="{ 'is-invalid': itemErros.espacoTotal.status }"
                    type="number"
                    min="1"
                    placeholder="1"
                    @keypress="$funcoes.keyPressInteiro"
                    @keyup="enterSalvar"
                  />
                  <div
                    v-if="itemErros.espacoTotal.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.espacoTotal.mensagem }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <label class="fs-6 fw-bold mb-2">Valor</label>
                  <div class="input-group mb-5">
                    <span class="input-group-text">R$</span>
                    <input
                      v-model.lazy="item.valor"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': itemErros.valor.status }"
                      placeholder="1,00"
                      v-money="{
                        decimal: ',',
                        thousands: '.',
                        prefix: '',
                        suffix: '',
                        precision: 2,
                        masked: false,
                      }"
                      @keyup="enterSalvar"
                    />
                  </div>
                  <div
                    v-if="itemErros.valor.status"
                    class="invalid-feedback"
                    :class="{ 'd-block': itemErros.valor.status }"
                  >
                    {{ itemErros.valor.mensagem }}
                  </div>
                </div>
              </div>
            </div>

            <div class="fv-row mb-7">
              <div
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.status"
                  checked="checked"
                />
                <label class="form-check-label fw-bold ms-3" for="status"
                  >Ativo</label
                >
              </div>
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Dialog from "../components/ComponentDialog.vue";

export default {
  components: { Dialog },
  props: { view: { type: String, default: "modal" } },

  data() {
    return {
      item: {
        _id: "",
        nome: "",
        usuariosTotal: 1,
        espacoTotal: 1,
        valor: 1.0,
        status: true,
      },
      itemErros: {
        nome: { status: false, mensagem: "" },
        usuariosTotal: { status: false, mensagem: "" },
        espacoTotal: { status: false, mensagem: "" },
        valor: { status: false, mensagem: "" },
      },
      sites: [],
      mensagem: "",
      confirmar: false,
      carregando: false,
    };
  },

  mounted() {},

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = JSON.parse(JSON.stringify(this.item));

      body.valor = body.valor.replaceAll(".", "").replaceAll(",", ".");
      body.espacoTotal = body.espacoTotal * 1024 * 1024 * 1024;

      if (this.item._id === "") {
        apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/plano", body);
      } else {
        //* Verifica se ja confirmou anteriormente
        if (this.confirmar == false) {
          this.confirmar = true;
          return;
        }

        apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/plano/" + this.item._id, body);
      }

      if (apiRequest.data.erro) {
        this.confirmar = false;
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else if (this.item._id === "") {
        this.carregarItem(apiRequest.data._id);
      }

      this.$emit("save-item");

      this.$refs.dialogFormPlano.fechar();
    },
    abrir() {
      this.$refs.dialogFormPlano.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nome"].focus();
      }, 500);
    },
    fechar() {
      if (this.confirmar) {
        this.confirmar = false;
        return;
      }

      this.$refs.dialogFormPlano.fechar();
    },

    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";
      this.confirmar = false;

      this.item._id = "";
      this.item.nome = "";
      this.item.usuariosTotal = 1;
      this.item.espacoTotal = 1;
      this.item.valor = parseFloat(0).toFixed(2);
      this.item.status = true;

      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Limpa o objeto
      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/plano/" + id);

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];
        this.item._id = itemEdit._id;
        this.item.nome = itemEdit.nome;
        this.item.usuariosTotal = itemEdit.usuariosTotal
          ? itemEdit.usuariosTotal
          : 1;
        this.item.espacoTotal =
          itemEdit.espacoTotal > 0
            ? itemEdit.espacoTotal / 1024 / 1024 / 1024
            : 0;
        this.item.valor = itemEdit.valor
          ? parseFloat(itemEdit.valor).toFixed(2)
          : parseFloat(0).toFixed(2);
        this.item.status = itemEdit.status ? itemEdit.status : false;
      }
    },
  },
};
</script>

<style>
</style>
