<template>
  <!--begin::User account menu-->
  <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <img alt="Logo" :src="$funcoes.usuarioFoto(authStore?.sessao.usuario._id, authStore?.sessao.usuario.foto ? true : false)" />
        </div>
        <!--end::Avatar-->
        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            <span class="text-truncate" style="max-width: 120px">{{ authStore?.sessao.usuario.nome }}</span>
            <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Admin</span>
          </div>
          <span class="fw-bold text-muted text-hover-primary fs-7 text-truncate" style="max-width: 178px">{{ authStore?.sessao.usuario.email }}</span>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="abrirMeusDados()" class="menu-link px-5">
        <span class="menu-text">Meus dados</span>
      </a>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="abrirAlterarSenha()" class="menu-link px-5">
        <span class="menu-text">Alterar senha</span>
      </a>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <a @click="abrirConfiguracoes()" class="menu-link px-5">Configurações</a>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start" v-if="false">
      <a href="#" class="menu-link px-5">
        <span class="menu-title position-relative"
          >Linguagem <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">Português <img class="w-15px h-15px rounded-1 ms-2" src="/assets/media/flags/brazil.svg" alt="" /></span
        ></span>
      </a>
      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <span class="menu-link d-flex px-5">
            <span class="symbol symbol-20px me-4"> <img class="rounded-1" src="/assets/media/flags/united-states.svg" alt="" /> </span>English</span
          >
        </div>
        <!--end::Menu item-->
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <span class="menu-link d-flex px-5 active">
            <span class="symbol symbol-20px me-4"> <img class="rounded-1" src="/assets/media/flags/brazil.svg" alt="" /> </span>Português</span
          >
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Menu item-->
    <div class="separator my-2"></div>

    <div class="menu-item px-5 my-1">
      <a href="/admin/administradores" class="menu-link px-5">Administradores</a>
    </div>

    <div class="menu-item px-5 my-1">
      <a href="/admin/sessoes" class="menu-link px-5">Sessões</a>
    </div>

    <div class="menu-item px-5 my-1">
      <a href="/" class="menu-link px-5">Suporte</a>
    </div>

    <div class="separator my-2"></div>
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <span @click="sair()" class="menu-link px-5">Sair</span>
    </div>
    <!--end::Menu item-->
    <ConfiguracoesEdit :id="'formConfiguracoes'" ref="formConfiguracoes" view="modal" />
    <MeusDadosEdit :id="'formMeusDados'" ref="formMeusDados" view="modal" />
    <AlterarSenhaEdit :id="'formAlterarSenha'" ref="formAlterarSenha" view="modal" />
  </div>
  <!--end::User account menu-->
</template>

<script>
import { useAuthStore } from "@/stores/auth.js";
import ConfiguracoesEdit from "../../../_shared/features/ConfiguracoesEdit.vue";
import MeusDadosEdit from "../../../_shared/features/MeusDadosEdit.vue";
import AlterarSenhaEdit from "../../../_shared/features/AlterarSenhaEdit.vue";

export default {
  components: { MeusDadosEdit, AlterarSenhaEdit, ConfiguracoesEdit },
  props: {},

  data() {
    return {
      authStore: null
    };
  },

  mounted() {
    this.authStore = useAuthStore();
  },

  methods: {
    sair() {
      this.authStore.sair();
    },
    abrirConfiguracoes() {
      this.$refs.formConfiguracoes.carregarItem();
      this.$refs.formConfiguracoes.abrir();
    },
    abrirMeusDados() {
      this.$refs.formMeusDados.carregarItem();
      this.$refs.formMeusDados.abrir();
    },
    abrirAlterarSenha() {
      this.$refs.formAlterarSenha.abrir();
    }
  }
};
</script>

<style></style>
