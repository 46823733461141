<template>
  <div>
    <div class="row gy-5 g-xl-8">
      <div class="col-xl-12">
        <div class="mb-0" :class="{ 'card card-xl-stretch': !dialog }">
          <div
            class="border-0"
            :class="{
              'card-header pt-6': !dialog,
              'd-flex justify-content-between': dialog,
            }"
          >
            <div :class="{ 'card-title': !dialog }">
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  class="form-control form-control-solid w-250px ps-14"
                  placeholder="Pesquisar"
                  @keyup="keyupFiltroTexto"
                  v-model="filtroTexto"
                />
              </div>
            </div>

            <div :class="{ 'card-toolbar': !dialog }">
              <div
                class="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
              >
                <button
                  type="button"
                  class="btn btn-primary px-4 me-3"
                  @click="inserirItens"
                  :disabled="Object.keys(itensSelecionados).length == 0"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                  Inserir
                </button>
              </div>
            </div>
          </div>

          <div class="py-3" :class="{ 'card-body': !dialog }">
            <div class="table-responsive">
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <thead>
                  <tr class="fw-bolder text-muted">
                    <th class="w-25px">
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                        v-if="false"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="selecionarTodos"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                        />
                      </div>
                    </th>
                    <th class="min-w-200px">
                      Usuários ({{ itensFiltro.length }})
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in itensFiltro" :key="item._id" :item="item">
                    <td>
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input widget-9-check"
                          type="checkbox"
                          @change="selecionarItem(item)"
                          :checked="itensSelecionados[item._id]"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-start">
                        <div class="symbol symbol-45px me-5">
                          <img
                            :src="
                              $funcoes.usuarioFoto(
                                item._id,
                                item.foto == true ? true : false
                              )
                            "
                            alt=""
                          />
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <span class="text-dark fw-bolder fs-6">
                            {{ item.nome }}
                          </span>
                          <span
                            class="text-muted fw-bold text-muted d-block fs-7"
                            >{{ item.email }}</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

export default {
  components: {},
  props: { dialog: { type: Boolean, default: false } },

  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      filtroTexto: "",
      filtroSite: "",
      ordem: "nome_ASC",
      itens: [],
      itensSelecionados: {},
      itensSelecionadosPadrao: {},
      selecionarTodos: false,
    };
  },

  mounted() {
    if (!this.dialog) {
      this.carregarItens();
    }
  },

  computed: {
    itensFiltro: function () {
      let thisCall = this;

      return this.itens.filter(function (item) {
        return !thisCall.itensSelecionadosPadrao[item._id] && item.tipoUsuario !== "adminNuvem";
      });
    },
  },

  methods: {
    async inserirItens() {
      if (this.dialog) {
        this.$emit("selecionar");
      }
    },
    async carregarItens() {
      let filtros = "";

      filtros += "?_limit=" + 500;

      if (this.filtroTexto !== "") {
        filtros += "&_all=" + this.filtroTexto;
      }

      filtros += "&_ordem=" + this.ordem;

      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/usuario" + filtros);
      api.valid(response.data, this.$router);

      this.itens = response.data.itens;
      this.itensTotal = response.data.total;
    },
    selecionarItem(item) {
      if (this.itensSelecionados[item._id]) {
        delete this.itensSelecionados[item._id];
      } else {
        this.itensSelecionados[item._id] = true;
      }
    },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        obj.carregarItens();
      }, 300);
    },
  },
};
</script>

<style>
</style>
