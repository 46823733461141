<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormUsuario'"
        ref="dialogFormUsuario"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div class="d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
          </div>
        </div>

        <div>
          <div v-if="item._id == ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Novo usuário</h2>
          </div>

          <div v-if="item._id !== ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Editando usuário</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <div class="text-center mb-5">
            <h1 class="text-primary fw-bolder fs-2 mt-2" v-if="true">
              {{ clienteNome }}
            </h1>

            <h1 class="text-muted fs-4 fw-bold ms-2 mt-2" v-if="true">
              {{ nuvemNome }}
            </h1>
          </div>

          <div
            class="fv-row mb-7"
            style="margin-top: 0px; margin-bottom: 10px"
            v-if="1 == 2"
          >
            <ul class="nav justify-content-center">
              <li class="nav-item">
                <button
                  class="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bolder px-4 me-1"
                  :class="{
                    active: abaSelecionada === 'dados',
                  }"
                  data-bs-toggle="tab"
                  @click="abaSelecionada = 'dados'"
                >
                  Dados
                </button>
              </li>
              <li class="nav-item">
                <button
                  class="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bolder px-4 me-1"
                  :class="{
                    active: abaSelecionada === 'obs',
                  }"
                  data-bs-toggle="tab"
                  @click="abaSelecionada = 'obs'"
                >
                  Obs
                </button>
              </li>
            </ul>
          </div>

          <div
            :style="{ display: abaSelecionada === 'dados' ? 'block' : 'none' }"
          >
            <div
              class="mb-7"
              :style="{ display: item._id == '' ? 'none' : 'block' }"
            >
              <Upload ref="uploadFoto" @change-foto="$emit('change-foto')" />
            </div>
            <div class="fv-row mb-7" v-if="!sysadmin">
              <label class="fs-6 fw-bold mb-2">Tipo de usuário</label>
              <Combo
                :modelValue="item.tipoUsuario"
                @update:modelValue="(newValue) => (item.tipoUsuario = newValue)"
                :itens="tiposUsuario"
                :erro="itemErros.tipoUsuario.status"
                :class="{ 'is-invalid': itemErros.tipoUsuario.status }"
                @keyup="enterSalvar"
                autofocus="true"
                :disabled="item.nomeUsuario == 'admin'"
                :classSelect2="false"
              />
              <div v-if="itemErros.tipoUsuario.status" class="invalid-feedback">
                {{ itemErros.tipoUsuario.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Nome</label>

              <input
                v-model="item.nome"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.nome.status }"
                type="text"
                @keyup="enterSalvar"
                ref="nome"
                autocomplete="false"
              />
              <div v-if="itemErros.nome.status" class="invalid-feedback">
                {{ itemErros.nome.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Nome de usuário</label>

              <input
                v-model="item.nomeUsuario"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.nomeUsuario.status }"
                type="text"
                @keypress="$funcoes.keyPressNomeUsuario"
                :disabled="item.nomeUsuario == 'admin'"
                autocomplete="false"
              />
              <div v-if="itemErros.nomeUsuario.status" class="invalid-feedback">
                {{ itemErros.nomeUsuario.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">E-mail</label>
              <input
                v-model="item.email"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.email.status }"
                type="text"
                @keyup="enterSalvar"
                autocomplete="false"
              />
              <div v-if="itemErros.email.status" class="invalid-feedback">
                {{ itemErros.email.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Senha</label>
              <div class="d-flex">
                <input
                  v-model="item.senha"
                  class="form-control form-control-solid me-3"
                  :class="{ 'is-invalid': itemErros.senha.status }"
                  type="text"
                  @keyup="enterSalvar"
                  autocomplete="false"
                />
                <button
                  type="button"
                  data-repeater-delete=""
                  class="btn btn-light-danger"
                  @click="item.senha = $funcoes.guidGenerator()"
                >
                  Gerar
                </button>
              </div>

              <div
                v-if="itemErros.senha.status"
                class="invalid-feedback"
                :class="{ 'd-block': itemErros.senha.status }"
              >
                {{ itemErros.senha.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <div
                class="form-check form-switch form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.status"
                  checked="checked"
                  :disabled="item.nomeUsuario == 'admin'"
                />
                <label class="form-check-label fw-bold ms-3" for="status"
                  >Ativo</label
                >
              </div>
            </div>

            <div class="fv-row mb-7" v-if="sysadmin == false">
              <div
                class="form-check form-switch form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="enviarEmailNuvemUsuario"
                  checked="checked"
                />
                <label class="form-check-label fw-bold ms-3" for="status"
                  >Enviar e-mail de boas vindas</label
                >
              </div>
            </div>

            <div
              class="fv-row mb-7"
              v-if="sysadmin == false && item.tipoUsuario == 'usuarioNuvem'"
            >
              <div
                class="form-check form-switch form-check-custom form-check-solid"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="item.desativar !== null"
                  @change="desativar($event)"
                />
                <label class="form-check-label fw-bold ms-3"
                  >Desativar login em</label
                >
              </div>
              <div class="mt-5 ms-15">
                <input
                  v-model="item.desativar"
                  class="form-control form-control-solid"
                  style="max-width: 200px"
                  type="datetime-local"
                  autofocus="true"
                  autocomplete="off"
                  :disabled="item.desativar == null"
                />
              </div>
            </div>
          </div>

          <div
            :style="{
              display: abaSelecionada === 'obs' ? 'block' : 'none',
            }"
          >
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Observacao</label>

              <textarea
                v-model="item.observacao"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.observacao.status }"
                style="min-height: 300px"
              />
              <div v-if="itemErros.observacao.status" class="invalid-feedback">
                {{ itemErros.observacao.mensagem }}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-12 col-form-label"></label>
              <div class="col-sm-12"></div>
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
    <EmailBoasVindas ref="emailNuvemUsuarioDialog" @dialog-off="fechar()" />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Combo from "../components/ComponentCombo.vue";
import Dialog from "../components/ComponentDialog.vue";
import Upload from "../components/ComponentUpload.vue";
import EmailBoasVindas from "./EmailBoasVindas.vue";

export default {
  components: { Combo, Dialog, Upload, EmailBoasVindas },
  props: {
    view: { type: String, default: "modal" },
    sysadmin: { type: Boolean, default: false },
  },

  computed: {
    tiposUsuario: function () {
      var thisCall = this;

      var arrTiposUsuario = this.$funcoes.tiposUsuarioArray().filter((obj) => {
        return obj.sysadmin === thisCall.sysadmin;
      });

      return arrTiposUsuario;
    },
  },
  data() {
    return {
      item: {
        _id: "",
        nome: "",
        nomeUsuario: "",
        email: "",
        tipoUsuario: "",
        cliente: "",
        nuvem: "",
        senha: "",
        status: true,
        observacao: "",
        foto: false,
        desativar: null,
      },
      itemErros: {
        nome: { status: false, mensagem: "" },
        nomeUsuario: { status: false, mensagem: "" },
        email: { status: false, mensagem: "" },
        tipoUsuario: { status: false, mensagem: "" },
        cliente: { status: false, mensagem: "" },
        nuvem: { status: false, mensagem: "" },
        senha: { status: false, mensagem: "" },
        observacao: { status: false, mensagem: "" },
        desativar: { status: false, mensagem: "" },
      },
      clientes: [],
      nuvens: [],
      mensagem: "",
      clienteNome: "",
      nuvemNome: "",
      abaSelecionada: "dados",
      enviarEmailNuvemUsuario: false,
      carregando: false,
    };
  },

  mounted() {},

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = this.item;

      //* Valida se o usuario deseja enviar email
      if (this.enviarEmailNuvemUsuario && this.sysadmin == false) {
        body.validarEmail = true;
      } else {
        delete body.validarEmail;
      }

      //* Verifica se esta editando um item
      if (this.item._id === "") {
        apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/usuario", body);
      } else {
        apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/usuario/" + this.item._id, body);
      }

      //* Verifica se ocorreu algum erro
      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else if (this.item._id === "") {
        //* Armazena o novo id
        this.item._id = apiRequest.data._id;
      }

      //* Verifica se deve enviar o email
      if (this.enviarEmailNuvemUsuario && this.sysadmin == false) {
        this.$refs.dialogFormUsuario.fechar();
        await this.$refs.emailNuvemUsuarioDialog.carregarItem(this.item._id);
        this.$refs.emailNuvemUsuarioDialog.item.senha = this.item.senha;
        this.$refs.emailNuvemUsuarioDialog.abrir();
        return;
      }

      this.$emit("save-item");
      this.fechar();
    },
    async abrir() {
      this.$emit("dialog-on");

      //* Verifica se deve carregar nome
      if (this.nuvemNome == "" && this.item.nuvem !== "") {
        const responseNuvem = await api.get(process.env.VUE_APP_URL_API + "/admin/nuvem/" + this.item.nuvem);

        if (responseNuvem.data.itens.length > 0) {
          this.clienteNome = responseNuvem.data.itens[0].cliente.nome;
          this.nuvemNome = responseNuvem.data.itens[0].nome.toString();
        }
      }

      this.$refs.dialogFormUsuario.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nome"].focus();
      }, 500);
    },
    fechar() {
      this.$refs.dialogFormUsuario.fechar();
      this.$emit("dialog-off");
    },
    desativar(event) {
      if (this.item.desativar == null) {
        this.item.desativar = this.$funcoes.formatarDataHoraLocal(new Date());
      } else {
        this.item.desativar = null;
      }
    },

    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";
      this.abaSelecionada = "dados";
      this.enviarEmailNuvemUsuario = false;
      this.clienteNome = "";
      this.nuvemNome = "";

      this.item._id = "";
      this.item.cliente = "";
      this.item.nuvem = "";
      this.item.nome = "";
      this.item.nomeUsuario = "";
      this.item.email = "";
      this.item.tipoUsuario = this.sysadmin ? "admin" : "usuarioNuvem";
      this.item.observacao = "";
      this.item.foto = false;
      this.item.senha = "";
      this.item.status = true;
      this.item.desativar = null;

      this.$refs.uploadFoto.refreshUrlFoto();

      /*
      if (false) {
        const responseClientes = await api.get(process.env.VUE_APP_URL_API + "/admin/cliente?_ordem=nome_ASC");
        const arrClientes = [];

        for (const [, cliente] of responseClientes.data.itens.entries()) {
          arrClientes.push({ id: cliente._id, nome: cliente.nome });
        }

        this.clientes = arrClientes;

        if (responseClientes.data.itens.length > 0) {
          this.item.cliente = responseClientes.data.itens[0]._id.toString();
        }

        const responseNuvens = await api.get(process.env.VUE_APP_URL_API + "/admin/nuvem?_ordem=nome_ASC");
        const arrNuvens = [];

        for (const [, nuvem] of responseNuvens.data.itens.entries()) {
          arrNuvens.push({ id: nuvem._id, nome: nuvem.nome });
        }

        this.nuvens = arrNuvens;

        if (responseNuvens.data.itens.length > 0) {
          this.item.nuvem = responseNuvens.data.itens[0]._id.toString();
        }
      }*/

      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Limpa o objeto
      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/usuario/" + id);

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];
        this.item._id = itemEdit._id;
        this.item.tipoUsuario = itemEdit.tipoUsuario;

        this.item.cliente = "";
        if (itemEdit.cliente) {
          this.item.cliente = itemEdit.cliente._id.toString();
          this.clienteNome = itemEdit.cliente.nome;
        }

        this.item.nuvem = "";
        if (itemEdit.nuvem) {
          this.item.nuvem = itemEdit.nuvem._id.toString();
          this.nuvemNome = itemEdit.nuvem.nome;
        }

        this.item.nome = itemEdit.nome;
        this.item.nomeUsuario = itemEdit.nomeUsuario;
        this.item.email = itemEdit.email;
        this.item.observacao = itemEdit.observacao;
        this.item.senha = "";
        this.item.foto = itemEdit.foto ? itemEdit.foto : false;
        this.item.status = itemEdit.status ? itemEdit.status : false;
        this.item.desativar =
          itemEdit.desativar && itemEdit.desativar !== null
            ? this.$funcoes.formatarDataHoraLocal(new Date(itemEdit.desativar))
            : null;

        this.$refs.uploadFoto.refreshUrlFoto(itemEdit._id, this.item.foto);
      }
    },
  },
};
</script>

<style>
</style>
