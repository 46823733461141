<template>
  <div>
    <div class="d-flex flex-column flex-column-fluid">
      <!--begin::Content-->
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
      >
        <img
          alt="Logo"
          src="/assets/icons/base/Logo3.png"
          class="mb-20 mt-10"
          style="max-width: 400px"
        />
        <!--begin::Wrapper-->
        <div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <form name="login" onsubmit="return false">
            <!--begin::Form-->
            <div class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
              <!--begin::Heading-->
              <div class="text-center mb-10">
                <!--begin::Title-->
                <h1 class="text-dark mb-3">Portal Administrativo</h1>
                <!--end::Title-->
                <!--begin::Link-->
                <div class="text-gray-400 fw-bold fs-4" v-if="false">
                  Novo aqui?
                  <a href="/" class="link-primary fw-bolder" tabindex="-1"
                    >Criar sua conta</a
                  >
                </div>
                <!--end::Link-->
              </div>
              <!--begin::Heading-->
              <!--begin::Input group-->
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark"
                  >Usuário</label
                >
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  v-model="item.nomeUsuario"
                  autofocus
                  @keyup="enterEntrar"
                  autocomplete="username"
                />
                <!--end::Input-->
                <div
                  v-if="itemErros.nomeUsuario.status"
                  class="fv-plugins-message-container invalid-feedback"
                >
                  {{ itemErros.nomeUsuario.mensagem }}
                </div>
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack mb-2">
                  <!--begin::Label-->
                  <label class="form-label fw-bolder text-dark fs-6 mb-0"
                    >Senha</label
                  >
                  <!--end::Label-->
                  <!--begin::Link-->
                  <a
                    v-if="false"
                    href="/"
                    class="link-primary fs-6 fw-bolder"
                    tabindex="-1"
                    >Esqueceu sua senha ?</a
                  >
                  <!--end::Link-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Input-->
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  name="password"
                  v-model="item.senha"
                  @keyup="enterEntrar"
                  autocomplete="current-password"
                />
                <!--end::Input-->
                <div
                  v-if="itemErros.senha.status"
                  class="fv-plugins-message-container invalid-feedback"
                >
                  {{ itemErros.senha.mensagem }}
                </div>
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="text-center">
                <!--begin::Submit button-->
                <button
                  id="kt_sign_in_submit"
                  class="btn btn-lg btn-primary w-100 mb-5"
                  @click="entrar()"
                >
                  <span class="indicator-label">Acessar</span>
                  <span class="indicator-progress"
                    >Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
                <!--end::Submit button-->
                <!--begin::Separator-->
                <div
                  class="text-center text-muted text-uppercase fw-bolder mb-5"
                  v-if="false"
                >
                  or
                </div>
                <!--end::Separator-->
                <!--begin::Google link-->
                <a
                  href="#"
                  class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                  v-if="false"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/google-icon.svg"
                    class="h-20px me-3"
                  />Continue com Google</a
                >
                <!--end::Google link-->
                <!--begin::Google link-->
                <a
                  href="#"
                  class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                  v-if="false"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/facebook-4.svg"
                    class="h-20px me-3"
                  />Continue com Facebook</a
                >
                <!--end::Google link-->
                <!--begin::Google link-->
                <a
                  href="#"
                  class="btn btn-flex flex-center btn-light btn-lg w-100"
                  v-if="false"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/apple-black.svg"
                    class="h-20px me-3"
                  />Continue com Apple</a
                >
                <!--end::Google link-->
              </div>
              <!--end::Actions-->
              <div></div>
            </div>
            <!--end::Form-->
          </form>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
      <!--begin::Footer-->
      <div class="d-flex flex-center flex-column-auto p-10" v-if="1 == 2">
        <!--begin::Links-->
        <div class="d-flex align-items-center fw-bold fs-6">
          <a
            href="https://keenthemes.com"
            class="text-muted text-hover-primary px-2"
            >About</a
          >
          <a
            href="mailto:support@keenthemes.com"
            class="text-muted text-hover-primary px-2"
            >Contact</a
          >
          <a
            href="https://1.envato.market/EA4JP"
            class="text-muted text-hover-primary px-2"
            >Contact Us</a
          >
        </div>
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import { useAuthStore } from "@/stores/auth.js";

export default {
  props: {},

  data() {
    return {
      item: {
        nomeUsuario: "",
        senha: "",
        lembrar: true,
      },
      itemErros: {
        nomeUsuario: { status: false, mensagem: "" },
        senha: { status: false, mensagem: "" },
      },
    };
  },

  mounted() {
    delete document.body.style.removeProperty("--kt-toolbar-height");
  },

  methods: {
    login() {},
    async entrar() {
      var authStore = useAuthStore();

      //* Limpa os erros
      this.itemErros = api.errosLimpar(this.itemErros);

      var result = await authStore.entrar(
        this.item.nomeUsuario.toLowerCase(),
        this.item.senha
      );

      if (result.erro == true) {
        api.errosProcessar(this.itemErros, result.erros);
      }

      /*
      //* Carrega os dados
      const body = {
        nomeUsuario: this.item.nomeUsuario.toLowerCase(),
        senha: this.item.senha,
      };

      const apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/sessao", body);

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (apiRequest.data.sessao && apiRequest.data.sessao.key) {
          localStorage.setItem("ckd", apiRequest.data.sessao.key);
          localStorage.setItem(
            "ckdCch",
            JSON.stringify(apiRequest.data.sessao)
          );

          window.location = "/admin/home";
        }
      }*/
    },
    enterEntrar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.entrar();
      }
    },
  },
};
</script>
