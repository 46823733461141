<template>
  <!--begin::Menu wrapper-->
  <div
    class="header-menu align-items-stretch"
    data-kt-drawer="true"
    data-kt-drawer-name="header-menu"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    data-kt-swapper="true"
    data-kt-swapper-mode="prepend"
    data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
  >
    <!--begin::Menu-->
    <div
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
      id="#kt_header_menu"
      data-kt-menu="true"
    >
      <div data-kt-menu-placement="bottom-start" class="menu-item me-lg-1" v-for="(menu, menuKey) in menus" :key="menuKey" :item="menu">
        <a
          class="menu-link py-3"
          :class="{
            active: $route.name == menu.rota
          }"
          :href="menu.path"
          ><span class="menu-title">{{ menu.titulo }}</span></a
        >
      </div>
    </div>
    <!--end::Menu-->
  </div>
  <!--end::Menu wrapper-->
</template>

<script>
//import HeaderMegaMenu from "./HeaderMegaMenu.vue";
import { useRouter } from "vue-router";

export default {
  components: {},
  props: {},

  data() {
    return {
      menus: {}
    };
  },

  async mounted() {
    const router = useRouter();

    await router.isReady();

    this.menus = {
      0: { titulo: "Home", path: "/app", rota: "homeApp" },
      1: { titulo: "Backup", path: "/app/backup", rota: "backupsApp" },
      2: { titulo: "Monitor", path: "/app/monitor", rota: "monitorApp" }
    };

    if (this.$route.name == "loginSite" || this.$route.name == "homeSite" || this.$route.name == "compartilhamentosSite" || this.$route.name == "gruposSite" || this.$route.name == "usuariosSite") {
      this.menus = {
        0: { titulo: "Home", path: "/site", rota: "homeSite" },
        1: {
          titulo: "Compartilhamentos",
          path: "/site/compartilhamentos",
          rota: "compartilhamentosSite"
        },
        2: { titulo: "Grupos", path: "/site/grupos", rota: "gruposSite" },
        3: {
          titulo: "Usuários",
          path: "/site/usuarios",
          rota: "usuariosSite"
        }
      };
    }

    if (
      this.$route.name == "loginAdmin" ||
      this.$route.name == "homeAdmin" ||
      this.$route.name == "planosAdmin" ||
      this.$route.name == "clientesAdmin" ||
      this.$route.name == "nuvensAdmin" ||
      this.$route.name == "gruposAdmin" ||
      this.$route.name == "usuariosAdmin" ||
      this.$route.name == "administradoresAdmin" ||
      this.$route.name == "sessoesAdmin" ||
      this.$route.name == "storagesAdmin"
    ) {
      this.menus = {
        0: { titulo: "Home", path: "/admin", rota: "homeAdmin" },
        1: {
          titulo: "Storages",
          path: "/admin/storages",
          rota: "storagesAdmin"
        },
        2: { titulo: "Planos", path: "/admin/planos", rota: "planosAdmin" },
        3: {
          titulo: "Clientes",
          path: "/admin/clientes",
          rota: "clientesAdmin"
        },
        4: { titulo: "Nuvens", path: "/admin/nuvens", rota: "nuvensAdmin" },
        5: {
          titulo: "Usuários",
          path: "/admin/usuarios",
          rota: "usuariosAdmin"
        }
      };
    }
  },

  methods: {}
};
</script>

<style></style>
