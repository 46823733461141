<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormStorage'"
        ref="dialogFormStorage"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div class="d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
          </div>
        </div>

        <div>
          <div v-if="item._id == ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Novo storage</h2>
          </div>

          <div v-if="item._id !== ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Editando storage</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Nome</label>

              <input
                v-model="item.nome"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.nome.status }"
                type="text"
                @keyup="enterSalvar"
                autofocus="true"
                ref="nome"
                autocomplete="off"
              />
              <div v-if="itemErros.nome.status" class="invalid-feedback">
                {{ itemErros.nome.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Caminho</label>

              <input
                v-model="item.caminho"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.caminho.status }"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
              />
              <div v-if="itemErros.caminho.status" class="invalid-feedback">
                {{ itemErros.caminho.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Região</label>
              <Combo
                :modelValue="item.regiao"
                @update:modelValue="(newValue) => (item.regiao = newValue)"
                :itens="$funcoes.regioesArray()"
                :erro="itemErros.regiao.status"
                :class="{ 'is-invalid': itemErros.regiao.status }"
                @keyup="enterSalvar"
                autofocus="true"
                :classSelect2="false"
              />
              <div v-if="itemErros.regiao.status" class="invalid-feedback">
                {{ itemErros.regiao.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <div
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.status"
                  checked="checked"
                />
                <label class="form-check-label fw-bold ms-3" for="status"
                  >Ativo</label
                >
              </div>
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Dialog from "../components/ComponentDialog.vue";
import Combo from "../components/ComponentCombo.vue";

export default {
  components: { Dialog, Combo },
  props: { view: { type: String, default: "modal" } },

  data() {
    return {
      item: {
        _id: "",
        nome: "",
        caminho: "",
        status: true,
        regiao: "",
      },
      itemErros: {
        nome: { status: false, mensagem: "" },
        caminho: { status: false, mensagem: "" },
        regiao: { status: false, mensagem: "" },
      },
      mensagem: "",
      carregando: false,
    };
  },

  mounted() {},

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = this.item;

      if (this.item._id === "") {
        apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/storage", body);
      } else {
        apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/storage/" + this.item._id, body);
      }

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else if (this.item._id === "") {
        this.carregarItem(apiRequest.data._id);
      }

      this.$emit("save-item");

      this.fechar();
    },
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogFormStorage.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nome"].focus();
      }, 500);
    },
    fechar() {
      this.$refs.dialogFormStorage.fechar();
      this.$emit("dialog-off");
    },

    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";

      this.item._id = "";
      this.item.nome = "";
      this.item.caminho = "";
      this.item.regiao = "";
      this.item.status = true;

      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Limpa o objeto
      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/storage/" + id);

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];
        this.item._id = itemEdit._id;
        this.item.nome = itemEdit.nome;
        this.item.caminho = itemEdit.caminho;
        this.item.regiao = itemEdit.regiao;
        this.item.status = itemEdit.status ? itemEdit.status : false;
      }
    },
  },
};
</script>

<style>
</style>
