import { isDate } from "util";
import { api } from "@/plugins/api.js";

const humanReadable = require("@tsmx/human-readable");

const funcoes = {
	formatarDataHora(datetime) {
		if (!datetime) return null;
		if (datetime && datetime != null && datetime !== "") {
			const options = {
				year: "numeric",
				month: "numeric",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit"
			};
			datetime = new Date(datetime);
			const portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
			return portugueseTime.format(datetime);
		} else {
			return null;
		}
	},
	formatarData(datetime) {
		if (!datetime) return null;
		if (datetime && datetime != null && datetime !== "") {
			const options = {
				year: "numeric",
				month: "numeric",
				day: "numeric"
			};
			datetime = new Date(datetime);
			const portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
			return portugueseTime.format(datetime);
		} else {
			return null;
		}
	},
	formatarDinheiro(valor) {
		if (!valor) return "R$ 0,00";

		const formatter = new Intl.NumberFormat("pt-BR", {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
		return "R$ " + formatter.format(valor);
	},
	dataAdicionarDias(data, days) {
		const date = new Date(data.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	},
	dataDiferencaDias(date1, date2) {
		const dt1 = new Date(date1);
		const dt2 = new Date(date2);
		return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
	},
	dataConverterUS(date) {
		if (!date) return null;
		if (isDate(date)) {
			date = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" : "") + date.getDate();
		}
		date = date.substr(0, 10); //* remove a parte do horário
		const [year, month, day] = date.split("-");
		return new Date(year, month - 1, day, 0, 0, 0, 0);
	},
	dataConverterUSString(date) {
		if (isDate(date)) {
			date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
		}
		if (!date) return null;
		if (date.indexOf("T")) {
			date = date.split("T")[0];
		}
		if (date.indexOf(" ")) {
			date = date.split(" ")[0];
		}
		const [year, month, day] = date.split("-");
		return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
	},
	scroll() {
		//$("html,body").animate({ scrollTop: 0 }, "slow");
		console.log("scroll");
	},
	porcentagem(valorAtual, valorTotal) {
		if (valorTotal == 0) {
			return 0;
		}

		return ((100 * valorAtual) / valorTotal).toFixed(2);
	},
	guidGenerator() {
		var S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		};
		return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
	},
	tiposUsuario() {
		return { admin: { id: "admin", nome: "Admin", sysadmin: true }, adminNuvem: { id: "adminNuvem", nome: "Admin Nuvem", sysadmin: false }, usuarioNuvem: { id: "usuarioNuvem", nome: "Convidado Nuvem", sysadmin: false } };
	},
	tiposUsuarioArray() {
		return Object.values(this.tiposUsuario());
	},
	regioes() {
		return { br: { id: "br", nome: "BR" }, us: { id: "us", nome: "US 1" }, us2: { id: "us2", nome: "US 2" }, us3: { id: "us3", nome: "US 3" } };
	},
	regioesArray() {
		return Object.values(this.regioes());
	},
	keyPressNomeUsuario(event) {
		var charCode = event.keyCode;
		const testRegex = /^[A-Za-z0-9.-]+$/;

		if (!testRegex.test(String.fromCharCode(charCode))) {
			this.cancelEvent(event);
		}
	},
	keyPressInteiro(event) {
		var charCode = event.keyCode;
		const testRegex = /^[0-9]+$/;

		if (!testRegex.test(String.fromCharCode(charCode))) {
			this.cancelEvent(event);
		}
	},
	cancelEvent(event) {
		if (event.preventDefault) {
			event.preventDefault();
		} else {
			event.returnValue = false;
		}
	},
	usuarioFoto(id, foto) {
		return api.usuarioFoto(id, foto) + "?cache=" + this.guidGenerator();
	},
	tamanhoTexto(size) {
		return humanReadable.fromBytes(size, { fixedPrecision: 2, mode: "IEC" });
	}
};

export default {
	install: (app) => {
		app.config.globalProperties.$funcoes = funcoes;
	}
};
