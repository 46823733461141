<template>
  <div>
    <select
      class="form-select form-select-solid"
      :data-control="classSelect2 ? 'select2' : ''"
      data-hide-search="true"
      :class="{ 'is-invalid': erro, 'combo-select2': classSelect2 }"
      v-model="selecionado"
      @change="(event) => $emit('update:modelValue', event.target.value)"
      :disabled="disabled"
    >
      <option v-if="itemSelecione" :disabled="!itemSelecione" value>
        Escolha um item
      </option>
      <option
        v-for="item in itens"
        :key="item.id"
        :item="item"
        :value="item.id"
      >
        {{ item.nome }}
      </option>
    </select>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    modelValue: String,
    itens: { type: Array, default: () => [] },
    erro: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    itemSelecione: { type: Boolean, default: false },
    classSelect2: { type: Boolean, default: true },
  },

  setup(props, { emit }) {
    const selecionado = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      selecionado,
    };
  },

  data() {
    return {};
  },

  mounted() {
    /*window.$(".combo-select2").select2({
      placeholder: "Select an option",
      minimumResultsForSearch: Infinity,
    });*/
  },

  methods: {},
};
</script>
