// import Vue from "vue"
// import VueCookies from "vue-cookies"
import axios from "axios";

// Vue.use(VueCookies)

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
	function (config) {
		// const token = `Bearer ${VueCookies.get("token")}`;
		const token = localStorage.getItem("ckd");
		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

const config = {
	headers: { "Access-Control-Allow-Origin": "*" },
	withCredentials: false
};

export const api = {
	get(endpoint) {
		return axiosInstance.get(endpoint, config);
	},
	post(endpoint, body) {
		return axiosInstance.post(endpoint, body, config);
	},
	put(endpoint, body) {
		return axiosInstance.put(endpoint, body, config);
	},
	delete(endpoint) {
		return axiosInstance.delete(endpoint, config);
	},
	valid(result) {
		if (result.erro && result.erroTipo && result.erroTipo === "TOKEN") {
			localStorage.removeItem("ckd");
			window.location = "/admin/login";
		}
	},
	usuarioFoto(id, foto) {
		if (foto) {
			return process.env.VUE_APP_URL_PUBLIC + "/usuarios/" + id + ".png";
		} else {
			//return "/assets/media/avatars/300-" + (Math.floor(Math.random() * 10) + 1) + ".jpg";
			return "/assets/media/avatars/blank.png";
		}
	},
	errosLimpar(itemErros) {
		Object.entries(itemErros).forEach(([key]) => {
			itemErros[key] = { status: false, mensagem: "" };
		});
		return itemErros;
	},
	errosProcessar(itemErros, erros) {
		for (let indiceErro = 0; indiceErro < erros.length; indiceErro++) {
			const itemErro = erros[indiceErro];
			itemErros[itemErro.campo] = { status: true, mensagem: itemErro.mensagem };
		}

		return itemErros;
	}
};
