<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormNuvem'"
        ref="dialogFormNuvem"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div class="d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
          </div>
        </div>

        <div>
          <div v-if="item._id == ''" class="fv-row mb-5 text-center">
            <h2 class="fw-bolder">Nova nuvem</h2>
          </div>

          <div v-if="item._id !== ''" class="fv-row mb-5 text-center">
            <h2 class="fw-bolder">Editando nuvem</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <div class="text-center mb-5">
            <h1 class="text-primary fw-bolder fs-2 mt-2" v-if="true">
              {{ clienteNome }}
            </h1>

            <h1 class="text-muted fs-4 fw-bold ms-2 mt-2" v-if="false">
              {{ "teste" }}
            </h1>
          </div>

          <div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Nome</label>

              <input
                v-model="item.nome"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.nome.status }"
                type="text"
                @keyup="enterSalvar"
                autofocus="true"
                ref="nome"
                autocomplete="off"
              />
              <div v-if="itemErros.nome.status" class="invalid-feedback">
                {{ itemErros.nome.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Id Nuvem</label>

              <input
                v-model="item.alias"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.alias.status }"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
              />
              <div v-if="itemErros.alias.status" class="invalid-feedback">
                {{ itemErros.alias.mensagem }}
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <div class="d-flex justify-content-between">
                    <label class="fs-6 fw-bold mb-2">Usuários</label
                    ><span class="fs-7 fw-bold text-danger mt-1"
                      >Max: {{ usuariosMax + usuariosMaxItem }}</span
                    >
                  </div>

                  <input
                    v-model="item.usuariosTotal"
                    class="form-control form-control-solid"
                    :class="{ 'is-invalid': itemErros.usuariosTotal.status }"
                    type="number"
                    min="1"
                    placeholder="1"
                    @keyup="enterSalvar"
                  />
                  <div
                    v-if="itemErros.usuariosTotal.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.usuariosTotal.mensagem }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <div class="d-flex justify-content-between">
                    <label class="fs-6 fw-bold mb-2">Espaço total (GB)</label>
                    <span class="fs-7 fw-bold text-danger mt-1"
                      >Max: {{ (espacoMax + espacoMaxItem).toFixed(2) }}</span
                    >
                  </div>

                  <input
                    v-model="item.espacoTotal"
                    class="form-control form-control-solid"
                    :class="{ 'is-invalid': itemErros.espacoTotal.status }"
                    type="number"
                    min="1"
                    placeholder="1"
                    @keyup="enterSalvar"
                  />
                  <div
                    v-if="itemErros.espacoTotal.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.espacoTotal.mensagem }}
                  </div>
                </div>
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Storage</label>
              <Combo
                :modelValue="item.storage"
                @update:modelValue="(newValue) => (item.storage = newValue)"
                :itens="storages"
                :erro="itemErros.storage.status"
                @keyup="enterSalvar"
              />
              <div
                v-if="itemErros.storage.status"
                class="invalid-feedback"
                :class="{ 'd-block': itemErros.storage.status }"
              >
                {{ itemErros.storage.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <div
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.status"
                  checked="checked"
                />
                <label class="form-check-label fw-bold ms-3" for="status"
                  >Ativo</label
                >
              </div>
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
    <UsuarioEdit ref="dialogFormUsuario" @dialog-off="fechar()" />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Dialog from "../components/ComponentDialog.vue";
import Combo from "../components/ComponentCombo.vue";
import UsuarioEdit from "./UsuarioEdit.vue";

export default {
  components: { Dialog, Combo, UsuarioEdit },

  props: { view: { type: String, default: "modal" } },

  data() {
    return {
      item: {
        _id: "",
        cliente: "",
        nome: "",
        alias: "",
        usuariosTotal: 1,
        espacoTotal: 1,
        storage: "",
        status: true,
      },
      itemErros: {
        cliente: { status: false, mensagem: "" },
        nome: { status: false, mensagem: "" },
        alias: { status: false, mensagem: "" },
        usuariosTotal: { status: false, mensagem: "" },
        espacoTotal: { status: false, mensagem: "" },
        storage: { status: false, mensagem: "" },
      },
      clientes: [],
      storages: [],
      mensagem: "",
      clienteNome: "",
      usuariosMax: 0,
      espacoMax: 0,
      usuariosMaxItem: 0,
      espacoMaxItem: 0,
      abaSelecionada: "",
      carregando: false,
    };
  },

  mounted() {
    //* Limpa os campos
    this.novo();
  },

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = JSON.parse(JSON.stringify(this.item));

      body.espacoTotal = body.espacoTotal * 1024 * 1024 * 1024;

      if (this.item._id === "") {
        apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/nuvem", body);
      } else {
        apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/nuvem/" + this.item._id, body);
      }

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else if (this.item._id === "") {
        //* Recarrega os dados
        this.carregarItem(apiRequest.data._id);

        //* Fecha a dialog
        this.$refs.dialogFormNuvem.fechar();

        //* Carrega os dados do admin
        this.$refs.dialogFormUsuario.carregarItem(apiRequest.data.usuarioAdmin);

        //* Abre o admin para edicao
        this.$refs.dialogFormUsuario.abrir();
      } else {
        this.fechar();
      }
    },
    async abrir() {
      this.$emit("dialog-on");

      //* Verifica se deve carregar nome
      if (this.clienteNome == "" && this.item.cliente !== "") {
        //* Busca o cliente da nuvem
        const responseCliente = await api.get(
          process.env.VUE_APP_URL_API + "/admin/cliente/" + this.item.cliente
        );

        if (responseCliente.data.itens.length > 0) {
          //* Carrega os dados do cliente
          var itemCliente = responseCliente.data.itens[0];

          //*
          this.clienteNome = itemCliente.nome.toString();
          this.usuariosMax =
            itemCliente.usuariosTotal - itemCliente.usuariosAlocado;
          this.espacoMax =
            (itemCliente.espacoTotal - itemCliente.espacoAlocado) /
            1024 /
            1024 /
            1024;
        }
      }

      this.$refs.dialogFormNuvem.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nome"].focus();
      }, 500);
    },
    fechar() {
      this.$emit("dialog-off");
      this.$refs.dialogFormNuvem.fechar();
    },
    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";
      this.abaSelecionada = "dados";
      this.clienteNome = "";
      this.usuariosMax = 0;
      this.espacoMax = 0;
      this.usuariosMaxItem = 0;
      this.espacoMaxItem = 0;

      this.item._id = "";
      this.item.cliente = "";
      this.item.nome = "";
      this.item.alias = "";
      this.item.usuariosTotal = 0;
      this.item.espacoTotal = 0;
      this.item.status = true;
      this.item.storage = "";

      //* Carrega os storages
      const responseStorages = await api.get(process.env.VUE_APP_URL_API + "/admin/storage?_ordem=nome_ASC");
      const arrStorage = [];

      //* Monta os itens da combo
      for (const [, storage] of responseStorages.data.itens.entries()) {
        arrStorage.push({ id: storage._id, nome: storage.nome });
      }

      this.storages = arrStorage;

      //* Define o primeiro storage como padrao
      if (responseStorages.data.itens.length > 0) {
        this.item.storage = responseStorages.data.itens[0]._id.toString();
      }

      //* Limpa os erros
      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/nuvem/" + id);

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];

        //* Verifica se o plano esta inativo
        if (
          itemEdit.storage &&
          this.storages.filter((obj) => {
            return obj.id === itemEdit.storage._id;
          }).length == 0
        ) {
          //* Insere o storage caso nao esteja
          this.storages.push({
            id: itemEdit.storage._id,
            nome: itemEdit.storage.nome,
          });
        }

        this.item._id = itemEdit._id;
        this.item.cliente = itemEdit.cliente ? itemEdit.cliente._id : "";
        this.item.nome = itemEdit.nome;
        this.item.alias = itemEdit.alias;
        this.item.usuariosTotal = itemEdit.usuariosTotal
          ? itemEdit.usuariosTotal
          : 1;
        this.item.espacoTotal = itemEdit.espacoTotal
          ? itemEdit.espacoTotal / 1024 / 1024 / 1024
          : 1;
        this.item.storage = itemEdit.storage ? itemEdit.storage._id : "";
        this.item.status = itemEdit.status ? itemEdit.status : false;

        //* Armazena a quantidade original definida
        this.usuariosMaxItem = this.item.usuariosTotal;
        this.espacoMaxItem = this.item.espacoTotal;

        //* Define o nome do cliente
        //this.clienteNome = itemEdit.cliente ? itemEdit.cliente.nome : "";
      }
    },
  },
};
</script>

<style>
</style>
