<template>
  <div class="row">
    <div class="text-center col-12">
      <div class="row" style="margin-bottom: 10px">
        <div class="col-12">
          <div
            class="btn-toolbar justify-content-between"
            role="toolbar"
            style="margin-left: 2px; margin-right: 2px"
          >
            <div style="zoom: 80%" v-if="tipo == 'remoto'">
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-arrow-left"></i>
              </button>
            </div>

            <div class="btn-group btn-group-sm d-flex" role="group">
              <button
                type="button"
                class="btn btn-outline-secondary"
                style="max-heigth: 10px"
              >
                <i class="fa-solid fa-folder-plus"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-trash-can"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-scissors"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-copy"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-i-cursor"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-square-share-nodes"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-link"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>

            <div style="zoom: 80%" v-if="tipo == 'local'">
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div class="col col-12">
          <div
            class="input-group flex-nowrap"
            style="padding-left: 2px; padding-right: 2px"
          >
            <span class="input-group-text" id="addon-wrapping">@</span>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder=""
              aria-label=""
              aria-describedby="addon-wrapping"
              value="/ROOT"
            />
          </div>
        </div>
      </div>

      <div class="card" style="margin-bottom: 10px">
        <div
          class="card-body text-left"
          style="padding-top: 10px; padding-bottom: 10px"
        >
          <div class="row">
            <div class="col col-12">
              <div class="d-flex itemMouseFoco" style="padding-bottom: 10px">
                <div
                  style="
                    margin-left: 5px;
                    margin-right: 15px;
                    padding-top: 5px;
                    min-width: 25px;
                  "
                >
                  <i class="fa-solid fa-folder fa-xl"></i>
                </div>
                <div class="flex-fill">
                  <p
                    class="d-flex justify-content-between"
                    style="
                      padding: 0px;
                      margin: 0px 0px 0px 0px;
                      margin-top: 3px;
                    "
                  >
                    <small>...</small>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 5px">
            <div class="col col-12">
              <div class="d-flex itemMouseFoco">
                <div
                  style="
                    margin-left: 5px;
                    margin-right: 15px;
                    padding-top: 10px;
                    min-width: 25px;
                  "
                >
                  <i class="fa-solid fa-folder fa-xl"></i>
                </div>
                <div class="flex-fill">
                  <p
                    class="d-flex justify-content-between"
                    style="padding: 0px; margin: 0px 0px 0px 0px"
                  >
                    <small>testes</small>
                    <small>25,36 MB</small>
                  </p>
                  <p class="d-flex justify-content-start" style="margin: 0px">
                    <small
                      ><small class="text-secondary"
                        >05/12/22 03:98:22</small
                      ></small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 5px">
            <div class="col col-12">
              <div class="d-flex itemMouseFoco">
                <div
                  style="
                    margin-left: 5px;
                    margin-right: 15px;
                    padding-top: 10px;
                    min-width: 25px;
                  "
                >
                  <i class="fa-regular fa-file fa-xl"></i>
                </div>
                <div class="flex-fill">
                  <p
                    class="d-flex justify-content-between"
                    style="padding: 0px; margin: 0px 0px 0px 0px"
                  >
                    <small>teste1.pdf</small>
                    <small>3,5 MB</small>
                  </p>
                  <p class="d-flex justify-content-start" style="margin: 0px">
                    <small
                      ><small class="text-secondary"
                        >05/12/22 03:98:22</small
                      ></small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 5px">
            <div class="col col-12">
              <div class="d-flex itemMouseFoco">
                <div
                  style="
                    margin-left: 5px;
                    margin-right: 15px;
                    padding-top: 10px;
                    min-width: 25px;
                  "
                >
                  <i class="fa-regular fa-file fa-xl"></i>
                </div>
                <div class="flex-fill">
                  <p
                    class="d-flex justify-content-between"
                    style="padding: 0px; margin: 0px 0px 0px 0px"
                  >
                    <small>teste2.pdf</small>
                    <small>3,5 MB</small>
                  </p>
                  <p class="d-flex justify-content-start" style="margin: 0px">
                    <small
                      ><small class="text-secondary"
                        >05/12/22 03:98:22</small
                      ></small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 5px">
            <div class="col col-12">
              <div class="d-flex itemMouseFoco">
                <div
                  style="
                    margin-left: 5px;
                    margin-right: 15px;
                    padding-top: 10px;
                    min-width: 25px;
                  "
                >
                  <i class="fa-regular fa-file fa-xl"></i>
                </div>
                <div class="flex-fill">
                  <p
                    class="d-flex justify-content-between"
                    style="padding: 0px; margin: 0px 0px 0px 0px"
                  >
                    <small>teste3.pdf</small>
                    <small>3,5 MB</small>
                  </p>
                  <p class="d-flex justify-content-start" style="margin: 0px">
                    <small
                      ><small class="text-secondary"
                        >05/12/22 03:98:22</small
                      ></small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentDiretorio",
  props: {
    tipo: { type: String, default: "local" },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.itemMouseFoco {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
}
.itemMouseFoco:hover {
  background-color: rgba(152, 152, 152, 0.084);
  cursor: pointer;
}
</style>
