<template>
  <div class="row">
    <div class="col col-md-12">
      <Dialog
        :id="'dialogFormEmailNuvemUsuario'"
        ref="dialogFormEmailNuvemUsuario"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div>
          <div class="d-flex justify-content-end">
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span
                class="svg-icon svg-icon-1"
                @click="$emit('dialog-off')"
                :class="{ 'd-none': status == 'enviando' }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">E-mail Boas Vindas</h2>
          </div>

          <div :class="{ 'd-none': status !== '' }">
            <div class="text-center">
              <h3 class="text-primary fw-bolder mt-2">
                {{ clienteNome }}
              </h3>

              <h3 class="text-muted fs-4 fw-bold ms-2 mt-2">
                {{ nuvemNome }}
              </h3>
            </div>
            <div class="text-center mb-12">
              <h3 class="text-dark fs-4 fw-bold ms-2 mt-7" v-if="true">
                {{ item.nome }}
              </h3>

              <h3 class="text-info fs-4 fw-bold ms-2 mt-2" v-if="true">
                {{ item.nomeUsuario }}@{{ nuvemAlias }}
              </h3>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">E-mail</label>

              <input
                v-model="item.email"
                ref="email"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.email.status }"
                type="text"
                @keyup="enterSalvar"
                :disabled="true"
                autocomplete="off"
              />
              <div v-if="itemErros.email.status" class="invalid-feedback">
                {{ itemErros.email.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7" v-if="!item.novaSenha">
              <label class="fs-6 fw-bold mb-2">Senha</label>

              <input
                v-model="item.senha"
                ref="senha"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.senha.status }"
                type="text"
                @keyup="enterSalvar"
                :disabled="item.novaSenha"
                autocomplete="off"
              />
              <div v-if="itemErros.senha.status" class="invalid-feedback">
                {{ itemErros.senha.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <div
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.novaSenha"
                  checked="checked"
                  @click="this.focusSenha()"
                />
                <label class="form-check-label fw-bold ms-3"
                  >Criar uma senha aleatória ao enviar o e-mail</label
                >
              </div>
            </div>

            <div class="fv-row mb-7">
              <div
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.customizarEmail"
                  checked="checked"
                  @click="this.focusEmailAssunto()"
                />
                <label class="form-check-label fw-bold ms-3"
                  >Customizar e-mail</label
                >
              </div>
            </div>

            <div v-if="item.customizarEmail">
              <div class="fv-row mt-4 mb-7">
                <label class="fs-6 fw-semibold mb-2">Assunto</label>
                <input
                  class="form-control form-control-solid mb-5"
                  type="text"
                  @keyup="enterSalvar"
                  autocomplete="off"
                  v-model="item.emailAssunto"
                  ref="emailAssunto"
                />
                <label class="fs-6 fw-semibold mb-2">Corpo</label>
                <ckeditor
                  @ready="onEditorReady"
                  :editor="ckEditorFormat"
                  v-model="item.emailTexto"
                  :config="ckEditorConfig"
                ></ckeditor>
              </div>
            </div>
          </div>

          <div class="text-center" :class="{ 'd-none': status !== 'sucesso' }">
            <div
              class="swal2-icon swal2-success swal2-icon-show mb-7"
              style="display: flex"
            >
              <div
                class="swal2-success-circular-line-left"
                style="background-color: rgb(255, 255, 255)"
              ></div>
              <span class="swal2-success-line-tip"></span>
              <span class="swal2-success-line-long"></span>
              <div class="swal2-success-ring"></div>
              <div
                class="swal2-success-fix"
                style="background-color: rgb(255, 255, 255)"
              ></div>
              <div
                class="swal2-success-circular-line-right"
                style="background-color: rgb(255, 255, 255)"
              ></div>
            </div>
            <h2 class="text-dark mb-2">E-mail enviado com sucesso</h2>
            <h2 class="text-gray-700">{{ item.email }}</h2>
          </div>

          <div
            class="text-center mt-7"
            :class="{ 'd-none': status !== 'enviando' }"
          >
            <div class="spinner-grow text-warning mb-7" role="status">
              <span class="visually-hidden">Enviando ...</span>
            </div>
            <h2 class="text-dark">Enviando email ...</h2>
            <h2 class="text-gray-700">{{ item.email }}</h2>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-light btn-active-light-primary"
                style="margin-right: 5px"
                @click="fechar()"
                :disabled="status == 'enviando'"
              >
                {{ status == "sucesso" ? "Fechar" : "Cancelar" }}
              </button>
              <button
                type="button"
                class="btn btn-warning"
                @click="enviarEmail()"
                :disabled="status == 'enviando'"
                v-if="status !== 'sucesso'"
              >
                Enviar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Dialog from "../components/ComponentDialog.vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export default {
  components: { Dialog },
  props: {
    view: { type: String, default: "modal" },
  },

  computed: {},

  data() {
    return {
      item: {
        _id: "",
        senha: "",
        novaSenha: true,
        cliente: "",
        nuvem: "",
        nome: "",
        nomeUsuario: "",
        email: "",
        customizarEmail: false,
        emailAssunto: "",
        emailTexto: "",
      },
      itemErros: {
        senha: { status: false, mensagem: "" },
        email: { status: false, mensagem: "" },
      },
      clienteNome: "",
      nuvemNome: "",
      nuvemAlias: "",
      status: "",
      ckEditorFormat: Editor,
      ckEditorConfig: {},
    };
  },

  mounted() {},

  methods: {
    async enviarEmail() {
      //* Limpa os campos
      this.status = "enviando";
      this.itemErros = api.errosLimpar(this.itemErros);

      //* Define os dados
      const body = this.item;

      //* Verifica se vai definir uma nova senha
      if (body.novaSenha) {
        delete body.senha;
      }

      //* Envia o e-mail
      let apiRequest = null;
      apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/usuario/email/boas-vindas", body);

      if (apiRequest.data.erro) {
        this.status = "";
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else {
        this.status = "sucesso";
      }
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/usuario/" + id);

      //* Verifica se econtrou o usuario
      if (response.data.itens.length > 0) {
        //* Define os campos
        const itemEdit = response.data.itens[0];
        this.item._id = itemEdit._id;
        this.item.cliente = itemEdit.cliente._id.toString();
        this.item.nuvem = itemEdit.nuvem._id.toString();
        this.item.nome = itemEdit.nome;
        this.item.nomeUsuario = itemEdit.nomeUsuario;
        this.item.email = itemEdit.email;
        this.item.customizarEmail = false;

        this.clienteNome = itemEdit.cliente.nome;
        this.nuvemNome = itemEdit.nuvem.nome;
        this.nuvemAlias = itemEdit.nuvem.alias;

        //* Carrega os dados da configuracao
        const responseConfiguracao = await api.get(process.env.VUE_APP_URL_API + "/admin/configuracao");

        //* Verifica se econtrou
        if (responseConfiguracao.data.erro == false) {
          //* Define o conteudo do email
          const itemConfiguracao = responseConfiguracao.data.item;
          this.item.emailAssunto = itemConfiguracao.emailNuvemUsuarioAssunto;
          this.item.emailTexto = itemConfiguracao.emailNuvemUsuarioTexto;
        }
      }
    },
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogFormEmailNuvemUsuario.abrir();

      //* Define o foco no campo senha
      this.focusSenha();
    },
    fechar() {
      this.$refs.dialogFormEmailNuvemUsuario.fechar();
      this.$emit("dialog-off");
    },
    focusSenha() {
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["senha"].focus();
      }, 500);
    },
    focusEmailAssunto() {
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["emailAssunto"].focus();
      }, 500);
    },
    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";
      this.status = "";
      this.mensagem = "";

      this.item.novaSenha = false;
      this.item._id = "";
      this.item.cliente = "";
      this.item.nuvem = "";
      this.item.nome = "";
      this.item.nomeUsuario = "";
      this.item.email = "";
      this.item.senha = "";

      this.item.emailAssunto = "";
      this.item.emailTexto = "";

      this.clienteNome = "";
      this.nuvemNome = "";
      this.nuvemAlias = "";

      this.itemErros = api.errosLimpar(this.itemErros);
    },
  },
};
</script>

<style>
.ck .ck-balloon-panel {
  z-index: 999999 !important;
}
</style>
