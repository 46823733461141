<template>
  <div>
    <div class="row gy-5 g-xl-8">
      <div class="col-xl-12">
        <div class="mb-5 mb-xl-8" :class="{ 'card card-xl-stretch': !dialog }">
          <div
            class="border-0 pt-6"
            :class="{ 'card-header': !dialog }"
            v-if="view !== 'ultimos'"
          >
            <div :class="{ 'card-title': !dialog }">
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  class="form-control form-control-solid w-250px ps-14"
                  placeholder="Pesquisar"
                  @keyup="keyupFiltroTexto"
                  v-model="filtroTexto"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="card-toolbar">
              <div
                class="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
                data-select2-id="select2-data-132-7t2c"
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="novoItem()"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                  Novo
                </button>
              </div>
            </div>
          </div>

          <div class="card-body py-3">
            <div class="table-responsive">
              <table
                class="
                  table table-row-dashed table-row-gray-300
                  align-middle
                  gs-0
                  gy-4
                "
              >
                <thead>
                  <tr class="fw-bolder text-muted">
                    <th class="w-25px" style="display: none">
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                        />
                      </div>
                    </th>
                    <th class="min-w-200px">
                      <span
                        class="cursor-pointer"
                        :class="{
                          'table-sort-desc':
                            ordem.campo == 'nome' && ordem.tipo == 'ASC',
                          'table-sort-asc':
                            ordem.campo == 'nome' && ordem.tipo == 'DESC',
                        }"
                        @click="alterarOrdem('nome')"
                        v-if="view !== 'ultimos'"
                        >Clientes ({{ itensTotal }})</span
                      >
                      <span class="cursor-pointer" v-if="view == 'ultimos'"
                        >Clientes recentes</span
                      >
                    </th>
                    <th class="w-150px min-w-200px" v-if="view !== 'ultimos'">
                      Plano
                    </th>
                    <th class="w-300px min-w-200px" v-if="view !== 'ultimos'">
                      Espaço utilizado
                    </th>
                    <th class="w-150px text-center" v-if="view !== 'ultimos'">
                      Status
                    </th>
                    <th class="w-40px text-end">Opções</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in itens" :key="item._id" :item="item">
                    <td style="display: none">
                      <div
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                        "
                      >
                        <input
                          class="form-check-input widget-9-check"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-45px me-5 mt-1 mb-1 mx-1">
                          <i
                            class="bi bi-building text-primary fs-2hx gx-5"
                          ></i>
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <span
                            class="text-dark fw-bolder text-hover-primary fs-6"
                            style="cursor: pointer"
                            @click="editarItem(item._id)"
                          >
                            {{ item.nome }}
                          </span>
                          <span
                            class="text-muted fw-bold text-muted d-block fs-7"
                            >Nuvens: {{ item.nuvens.length }}</span
                          >
                        </div>
                      </div>
                    </td>
                    <td v-if="view !== 'ultimos'">
                      <div class="d-flex justify-content-start flex-column">
                        <span class="text-dark fw-bolder fs-6">
                          {{ item.plano ? item.plano.nome : "-" }}
                        </span>
                        <span class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.usuarios.length }} de
                          {{ item.usuariosTotal }}
                          usuarios</span
                        >
                      </div>
                    </td>
                    <td class="text-end" v-if="view !== 'ultimos'">
                      <div class="d-flex flex-column w-100 me-2">
                        <div class="mb-2">
                          <span class="text-muted me-2 fs-7 fw-bold"
                            ><small
                              >{{
                                $funcoes.porcentagem(
                                  item.espacoOcupado > 0
                                    ? item.espacoOcupado / 1024 / 1024 / 1024
                                    : 0,
                                  item.espacoTotal > 0
                                    ? item.espacoTotal / 1024 / 1024 / 1024
                                    : 0
                                )
                              }}%</small
                            ></span
                          >
                        </div>
                        <div class="progress h-6px w-100 mb-2">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            :style="{
                              width:
                                $funcoes.porcentagem(
                                  item.espacoOcupado > 0
                                    ? item.espacoOcupado / 1024 / 1024 / 1024
                                    : 0,
                                  item.espacoTotal > 0
                                    ? item.espacoTotal / 1024 / 1024 / 1024
                                    : 0
                                ) + '%',
                            }"
                            :aria-valuenow="item.espacoOcupado / 1024"
                            aria-valuemin="0"
                            :aria-valuemax="item.espacoTotal / 1024"
                          ></div>
                        </div>
                        <div class="d-flex flex-stack">
                          <span class="text-muted me-2 fs-7 fw-bold"
                            ><small
                              >{{
                                (item.espacoOcupado > 0
                                  ? item.espacoOcupado / 1024 / 1024 / 1024
                                  : 0
                                ).toFixed(2)
                              }}
                              GB</small
                            >
                          </span>

                          <span class="text-muted me-2 fs-7 fw-bold"
                            ><small
                              >{{
                                (item.espacoTotal > 0
                                  ? item.espacoTotal / 1024 / 1024 / 1024
                                  : 0
                                ).toFixed(2)
                              }}
                              GB</small
                            ></span
                          >
                        </div>
                      </div>
                    </td>
                    <td class="text-center" v-if="view !== 'ultimos'">
                      <span
                        class="badge badge-light-success"
                        v-if="item.status == true"
                        >Ativo</span
                      >
                      <span
                        class="badge badge-light-danger"
                        v-if="item.status == false"
                        >Inativo</span
                      >
                    </td>
                    <td>
                      <div class="d-flex justify-content-end flex-shrink-0">
                        <button
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                            me-1
                          "
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Nuvens"
                          @click="abrirListaNuvem(item._id)"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-cloud"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                              />
                            </svg>
                          </span>
                        </button>
                        <button
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                            me-1
                          "
                          @click="abrirListaGrupo(item._id)"
                          v-if="false"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-people-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                              />
                              <path
                                d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                              />
                            </svg>
                          </span>
                        </button>
                        <button
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                            me-1
                          "
                          @click="abrirListaUsuario(item._id)"
                          v-if="false"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-person-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                              />
                            </svg>
                          </span>
                        </button>
                        <button
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                          "
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Editar"
                          @click="editarItem(item._id)"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Paginacao
              ref="paginacao"
              :total="itensTotal"
              :limit="20"
              :class="{ 'd-none': view == 'ultimos' }"
              @change-pagina="carregarItens()"
            />
          </div>
        </div>
      </div>
    </div>

    <ClienteEdit ref="formCliente" view="modal" @save-item="carregarItens()" />

    <NuvemListDialog ref="listNuvem" @dialog-off="carregarItens()" />
    <GrupoListDialog ref="listGrupo" @dialog-off="carregarItens()" />
    <UsuarioListDialog ref="listUsuario" @dialog-off="carregarItens()" />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

//import Combo from "../../components/ComponentCombo.vue";
import Paginacao from "../components/ComponentPaginacao.vue";
import ClienteEdit from "./ClienteEdit.vue";
import NuvemListDialog from "./NuvemListDialog.vue";
import GrupoListDialog from "./GrupoListDialog.vue";
import UsuarioListDialog from "./UsuarioListDialog.vue";

export default {
  components: {
    ClienteEdit,
    NuvemListDialog,
    GrupoListDialog,
    UsuarioListDialog,
    Paginacao,
  },

  props: {
    dialog: { type: Boolean, default: false },
    view: { type: String, default: "" },
  },

  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      filtroTexto: "",
      ordem: { campo: "nome", tipo: "ASC" },
      itens: [],
    };
  },

  mounted() {
    this.carregarItens();
  },

  methods: {
    async carregarItens() {
      let filtros = "";

      //* Filtra os ultimos itens
      if (this.view == "ultimos") {
        filtros += "?_limit=" + 10;
        filtros += "&_ordem=dataCriacao_DESC";
      } else {
        filtros += "?_limit=" + 20;
        filtros += "&_page=" + this.$refs.paginacao.pagina;
        filtros += "&_ordem=" + this.ordem.campo + "_" + this.ordem.tipo;
      }

      if (this.filtroTexto !== "") {
        filtros += "&_all=" + this.filtroTexto;
      }

      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/cliente" + filtros);
      api.valid(response.data, this.$router);

      this.itens = response.data.itens;
      this.itensTotal = response.data.total;

      setTimeout(function () {
        window.KTApp.initBootstrapTooltips();
      }, 200);
    },
    editarItem(id) {
      this.$refs.formCliente.carregarItem(id);
      this.$refs.formCliente.abrir();
    },
    novoItem() {
      this.$refs.formCliente.novo();
      this.$refs.formCliente.abrir();
    },
    abrirListaNuvem(cliente) {
      this.$refs.listNuvem.abrir(cliente);
    },
    abrirListaUsuario(cliente) {
      this.$refs.listUsuario.abrir(cliente);
    },
    abrirListaGrupo(cliente) {
      this.$refs.listGrupo.abrir(cliente);
    },
    alterarOrdem(campo) {
      //* Verifica se o campo foi mantido
      if (this.ordem.campo == campo) {
        //* Verifica a ordem atual e altera para a nova
        if (this.ordem.tipo == "ASC") {
          this.ordem.tipo = "DESC";
        } else {
          this.ordem.tipo = "ASC";
        }
      } else {
        //* Altera para ordenar o novo campo
        this.ordem.campo = campo;
        this.ordem.tipo = "ASC";
      }

      //* Recarrega os itens com nova ordem
      this.carregarItens();
    },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        //obj.$refs.paginacao.pagina = 1;
        obj.carregarItens();
      }, 300);
    },
  },
};
</script>

<style>
</style>
