<template>
  <div>
    <div class="d-flex justify-content-center mt-25px flex-wrap">
      <div class="w-250px p-3">
        <div class="card mb-5">
          <div
            class="
              card-body
              d-flex
              justify-content-between
              flex-column
              px-0
              pb-0
            "
          >
            <div class="mb-4 px-9 text-center">
              <div class="fs-2hx fw-bold text-gray-800 lh-1 ls-n2 mb-2 counted">
                {{ estatistica.cliente }}
              </div>
              <div class="text-center">
                <span
                  class="bullet bullet-dot bg-primary me-2 h-10px w-10px"
                ></span
                ><span class="fw-bold text-gray-600 fs-6">Clientes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-250px p-3">
        <div class="card mb-5">
          <div
            class="
              card-body
              d-flex
              justify-content-between
              flex-column
              px-0
              pb-0
            "
          >
            <div class="mb-4 px-9 text-center">
              <div class="fs-2hx fw-bold text-gray-800 lh-1 ls-n2 mb-2">
                {{ estatistica.nuvem }}
              </div>
              <div class="text-center">
                <span
                  class="bullet bullet-dot bg-success me-2 h-10px w-10px"
                ></span
                ><span class="fw-bold text-gray-600 fs-6">Nuvens</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-250px p-3">
        <div class="card mb-5">
          <div
            class="
              card-body
              d-flex
              justify-content-between
              flex-column
              px-0
              pb-0
            "
          >
            <div class="mb-4 px-9 text-center">
              <div class="fs-2hx fw-bold text-gray-800 lh-1 ls-n2 mb-2">
                {{ estatistica.usuario }}
              </div>
              <div class="text-center">
                <span
                  class="bullet bullet-dot bg-info me-2 h-10px w-10px"
                ></span
                ><span class="fw-bold text-gray-600 fs-6">Usuários</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-250px p-3">
        <div class="card mb-5">
          <div
            class="
              card-body
              d-flex
              justify-content-between
              flex-column
              px-0
              pb-0
            "
          >
            <div class="mb-4 px-9 text-center">
              <div class="fs-2hx fw-bold text-gray-800 lh-1 ls-n2 mb-2">
                {{ $funcoes.tamanhoTexto(estatistica.espacoOcupado) }}
              </div>
              <div class="text-center">
                <span
                  class="bullet bullet-dot bg-danger me-2 h-10px w-10px"
                ></span
                ><span class="fw-bold text-gray-600 fs-6">Em uso</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-250px mh-140px p-3">
        <div class="card mb-5">
          <div
            class="
              card-body
              d-flex
              justify-content-between
              flex-column
              px-0
              pb-0
            "
          >
            <div class="mb-4 px-9 text-center">
              <div class="fs-2hx fw-bold text-gray-800 lh-1 ls-n2 mb-2">
                {{ $funcoes.tamanhoTexto(estatistica.espacoAlocado) }}
              </div>
              <div class="text-center">
                <span
                  class="bullet bullet-dot bg-warning me-2 h-10px w-10px"
                ></span
                ><span class="fw-bold text-gray-600 fs-6">Contratado</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-25px flex-wrap">
      <div class="p-3 flex-fill" style="min-width: 400px">
        <ClienteList view="ultimos" />
      </div>
      <div class="p-3 flex-fill" style="min-width: 400px">
        <NuvemList view="ultimos" />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import ClienteList from "../../../../_shared/features/ClienteList.vue";
import NuvemList from "../../../../_shared/features/NuvemList.vue";

export default {
  name: "HomeSite",
  components: { ClienteList, NuvemList },
  data: function () {
    return {
      estatistica: {
        cliente: 0,
        nuvem: 0,
        usuario: 0,
        espacoAlocado: 0,
        espacoOcupado: 0,
      },
    };
  },
  async mounted() {
    //* Carrega os dados da api
    const resultEstatisticaTotal = await api.get(process.env.VUE_APP_URL_API + "/admin/estatistica/totais");

    //* Verifica se econtrou
    if (resultEstatisticaTotal.data.erro == false) {
      const item = resultEstatisticaTotal.data.item;
      this.estatistica.cliente = item.cliente;
      this.estatistica.nuvem = item.nuvem;
      this.estatistica.usuario = item.usuario;
    }

    //* Carrega os dados da api
    const resultEstatisticaStorageTotal = await api.get(
      process.env.VUE_APP_URL_API + "/admin/estatistica/storage/totais"
    );

    //* Verifica se econtrou
    if (resultEstatisticaStorageTotal.data.erro == false) {
      const item = resultEstatisticaStorageTotal.data.item;
      this.estatistica.espacoAlocado = item.espacoAlocado;
      this.estatistica.espacoOcupado = item.espacoOcupado;
    }
  },
};
</script>