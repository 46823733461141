<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormAlterarSenha'"
        ref="dialogFormAlterarSenha"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div>
          <div class="d-flex justify-content-end">
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Alterar senha</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <form name="alterarSenha" onsubmit="return false">
            <div>
              <div class="fv-row mb-7">
                <label class="fs-6 fw-bold mb-2">Senha atual</label>

                <input
                  v-model="item.senhaAtual"
                  class="form-control form-control-solid"
                  :class="{ 'is-invalid': itemErros.senhaAtual.status }"
                  type="password"
                  @keyup="enterSalvar"
                  ref="senhaAtual"
                  autocomplete="current-password"
                />
                <div
                  v-if="itemErros.senhaAtual.status"
                  class="invalid-feedback"
                >
                  {{ itemErros.senhaAtual.mensagem }}
                </div>
              </div>

              <div class="fv-row mb-7">
                <label class="fs-6 fw-bold mb-2">Nova senha</label>

                <input
                  v-model="item.senha"
                  class="form-control form-control-solid"
                  :class="{ 'is-invalid': itemErros.senha.status }"
                  type="password"
                  @keyup="enterSalvar"
                  autocomplete="new-password"
                />
                <div v-if="itemErros.senha.status" class="invalid-feedback">
                  {{ itemErros.senha.mensagem }}
                </div>
              </div>

              <div class="fv-row mb-7">
                <label class="fs-6 fw-bold mb-2">Confirmar nova senha</label>

                <input
                  v-model="item.confirmarSenha"
                  class="form-control form-control-solid"
                  :class="{ 'is-invalid': itemErros.confirmarSenha.status }"
                  type="password"
                  @keyup="enterSalvar"
                  ref="nome"
                  autocomplete="new-password"
                />
                <div
                  v-if="itemErros.confirmarSenha.status"
                  class="invalid-feedback"
                >
                  {{ itemErros.confirmarSenha.mensagem }}
                </div>
              </div>
            </div>
          </form>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";

import Dialog from "../components/ComponentDialog.vue";

export default {
  components: { Dialog },
  props: {
    view: { type: String, default: "modal" },
  },

  computed: {},

  data() {
    return {
      item: {
        senhaAtual: "",
        senha: "",
        confirmarSenha: "",
      },
      itemErros: {
        senhaAtual: { status: false, mensagem: "" },
        senha: { status: false, mensagem: "" },
        confirmarSenha: { status: false, mensagem: "" },
      },
      mensagem: "",
      carregando: false,
      authStore: null,
    };
  },

  mounted() {
    this.authStore = useAuthStore();
  },

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = this.item;
      apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/usuario/alterar-senha", body);

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      }

      this.$emit("save-item");

      this.fechar();
    },
    abrir() {
      this.$emit("dialog-on");
      this.novo();
      this.$refs.dialogFormAlterarSenha.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["senhaAtual"].focus();
      }, 500);
    },
    fechar() {
      this.$refs.dialogFormAlterarSenha.fechar();
      this.$emit("dialog-off");
    },

    enterSalvar() {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";

      this.item.senhaAtual = "";
      this.item.senha = "";
      this.item.confirmarSenha = "";

      this.itemErros = api.errosLimpar(this.itemErros);
    },
  },
};
</script>

<style>
</style>
