<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormCliente'"
        ref="dialogFormCliente"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div class="d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
          </div>
        </div>
        <div>
          <div v-if="item._id == ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Novo cliente</h2>
          </div>

          <div v-if="item._id !== ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Editando cliente</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Nome</label>

              <input
                v-model="item.nome"
                ref="nome"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.nome.status }"
                type="text"
                @keyup="enterSalvar"
                autofocus="true"
                autocomplete="off"
              />
              <div v-if="itemErros.nome.status" class="invalid-feedback">
                {{ itemErros.nome.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">E-mail de Contato</label>

              <input
                v-model="item.contato"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.contato.status }"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
              />
              <div v-if="itemErros.contato.status" class="invalid-feedback">
                {{ itemErros.contato.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Plano</label>
              <Combo
                :modelValue="item.plano"
                @update:modelValue="(newValue) => (item.plano = newValue)"
                :itens="planos"
                :erro="itemErros.plano.status"
                @keyup="enterSalvar"
              />
              <div
                v-if="itemErros.plano.status"
                class="invalid-feedback"
                :class="{ 'd-block': itemErros.plano.status }"
              >
                {{ itemErros.plano.mensagem }}
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <label class="fs-6 fw-bold mb-2">Usuários extra</label>
                  <input
                    v-model="item.usuariosExtra"
                    class="form-control form-control-solid"
                    :class="{
                      'is-invalid': itemErros.usuariosExtra.status,
                    }"
                    type="number"
                    min="0"
                    placeholder="1"
                    @keypress="$funcoes.keyPressInteiro"
                    @keyup="enterSalvar"
                  />
                  <div
                    v-if="itemErros.usuariosExtra.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.usuariosExtra.mensagem }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <label class="fs-6 fw-bold mb-2">Espaço extra (GB)</label>
                  <input
                    v-model="item.espacoExtra"
                    class="form-control form-control-solid"
                    :class="{ 'is-invalid': itemErros.espacoExtra.status }"
                    type="number"
                    min="0"
                    placeholder="1"
                    @keypress="$funcoes.keyPressInteiro"
                    @keyup="enterSalvar"
                  />
                  <div
                    v-if="itemErros.espacoExtra.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.espacoExtra.mensagem }}
                  </div>
                </div>
              </div>
            </div>

            <div class="fv-row mb-7">
              <div
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.status"
                  checked="checked"
                />
                <label class="form-check-label fw-bold ms-3" for="status"
                  >Ativo</label
                >
              </div>
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
    <NuvemEdit
      ref="dialogFormNuvem"
      @dialog-off="
        this.$emit('save-item');
        fechar();
      "
    />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Dialog from "../components/ComponentDialog.vue";
import Combo from "../components/ComponentCombo.vue";
import NuvemEdit from "./NuvemEdit.vue";

export default {
  components: { Dialog, Combo, NuvemEdit },

  props: { view: { type: String, default: "modal" } },

  data() {
    return {
      item: {
        _id: "",
        nome: "",
        contato: "",
        plano: "",
        usuariosExtra: 0,
        expacoExtra: 0,
        status: true,
      },
      itemErros: {
        nome: { status: false, mensagem: "" },
        contato: { status: false, mensagem: "" },
        plano: { status: false, mensagem: "" },
        usuariosExtra: { status: false, mensagem: "" },
        espacoExtra: { status: false, mensagem: "" },
      },
      planos: [],
      mensagem: "",
      abaSelecionada: "",
      carregando: false,
    };
  },

  mounted() {
    //* Limpa os campos
    this.novo();
  },

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = JSON.parse(JSON.stringify(this.item));

      body.espacoExtra = body.espacoExtra * 1024 * 1024 * 1024;

      if (this.item._id === "") {
        apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/cliente", body);
      } else {
        apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/cliente/" + this.item._id, body);
      }

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else if (this.item._id === "") {
        //* Recarrega o item salvo
        this.carregarItem(apiRequest.data._id);

        //* Fecha a dialog do cliente
        this.fechar();

        //* Abre a dialog de nova nuve,
        await this.$refs.dialogFormNuvem.novo();
        this.$refs.dialogFormNuvem.item.cliente = apiRequest.data._id;
        this.$refs.dialogFormNuvem.abrir();

        return;
      }

      this.$emit("save-item");

      if (this.view == "modal") {
        this.fechar();
      }
    },
    abrir() {
      this.$refs.dialogFormCliente.abrir();

      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nome"].focus();
      }, 500);
    },
    fechar() {
      this.$refs.dialogFormCliente.fechar();
    },
    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";
      this.abaSelecionada = "dados";

      this.item._id = "";
      this.item.nome = "";
      this.item.contato = "";
      this.item.plano = "";
      this.item.usuariosExtra = 0;
      this.item.espacoExtra = 0;
      this.item.status = true;

      const responsePlanos = await api.get(
        process.env.VUE_APP_URL_API + "/admin/plano?status=true&_ordem=nome_ASC"
      );
      const arrPlano = [];

      for (const [, plano] of responsePlanos.data.itens.entries()) {
        arrPlano.push({
          id: plano._id,
          nome:
            plano.nome +
            " (usuários: " +
            plano.usuariosTotal +
            " | espaço: " +
            plano.espacoTotal / 1024 / 1024 / 1024 +
            " GB | valor: " +
            this.$funcoes.formatarDinheiro(plano.valor) +
            ")",
        });
      }

      this.planos = arrPlano;

      if (responsePlanos.data.itens.length > 0) {
        this.item.plano = responsePlanos.data.itens[0]._id.toString();
      }

      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/cliente/" + id);

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];

        //* Verifica se o plano esta inativo
        if (
          this.planos.filter((obj) => {
            return obj.id === itemEdit.plano._id;
          }).length == 0
        ) {
          //* Insere o plano caso nao esteja
          this.planos.push({
            id: itemEdit.plano._id,
            nome:
              itemEdit.plano.nome +
              " (usuários: " +
              itemEdit.plano.usuariosTotal +
              " | espaço: " +
              itemEdit.plano.espacoTotal / 1024 / 1024 / 1024 +
              " GB | valor: R$ " +
              itemEdit.plano.usuariosTotal +
              ")",
          });
        }

        this.item._id = itemEdit._id;
        this.item.nome = itemEdit.nome;
        this.item.contato = itemEdit.contato;
        this.item.plano = itemEdit.plano ? itemEdit.plano._id : "";
        this.item.usuariosExtra = itemEdit.usuariosExtra
          ? itemEdit.usuariosExtra
          : 0;
        this.item.espacoExtra =
          itemEdit.espacoExtra > 0
            ? itemEdit.espacoExtra / 1024 / 1024 / 1024
            : 0;
        this.item.status = itemEdit.status ? itemEdit.status : false;
      }
    },
  },
};
</script>

<style>
</style>
