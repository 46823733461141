<template>
  <div>
    <div class="row gy-5 g-xl-8">
      <div class="col-xl-12">
        <div class="mb-5 mb-xl-8" :class="{ 'card card-xl-stretch': !dialog }">
          <div
            class="border-0"
            :class="{
              'card-header pt-6': !dialog,
              'd-flex justify-content-between': dialog,
            }"
          >
            <div
              class="d-flex justify-content-start"
              :class="{ 'card-title': !dialog }"
            >
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  class="form-control form-control-solid w-250px ps-14"
                  placeholder="Pesquisar"
                  @keyup="keyupFiltroTexto"
                  v-model="filtroTexto"
                  autocomplete="off"
                />
              </div>

              <div class="ms-5 mt-2" v-if="filtroCliente !== ''">
                <div class="fw-bolder">Usuários</div>
                <div class="text-gray-600">
                  {{ estatisticas.usuariosUtilizado }} de
                  {{ estatisticas.usuariosTotal }}
                </div>
              </div>

              <div class="ms-5 mt-2" v-if="false">
                <div class="fw-bolder">Espaco</div>
                <div class="text-gray-600">
                  {{ estatisticas.espacoUtilizado }} de
                  {{ estatisticas.espacoTotal }} GB
                </div>
              </div>
            </div>

            <div class="card-toolbar pt-3" v-if="1 == 2">
              <ul class="nav" id="kt_chart_widget_11_tabs">
                <li class="nav-item">
                  <a
                    class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bolder px-4 me-1 active"
                    data-bs-toggle="tab"
                    id="kt_chart_widget_11_tab_1"
                    href="#kt_chart_widget_11_tab_content_1"
                    >No grupo</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bolder px-4 me-1"
                    data-bs-toggle="tab"
                    id="kt_chart_widget_11_tab_2"
                    href="#kt_chart_widget_11_tab_content_2"
                    >Inserir</a
                  >
                </li>
              </ul>
            </div>

            <div :class="{ 'card-toolbar': !dialog }">
              <div
                class="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="novoItem()"
                  :disabled="
                    filtroCliente !== '' &&
                    estatisticas.usuariosUtilizado >= estatisticas.usuariosTotal
                  "
                  v-if="buttonNovo"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                  Novo
                </button>
                <button
                  v-if="buttonSelecionar"
                  type="button"
                  class="btn btn-primary"
                  @click="selecionaUsuarios()"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                  Usuários
                </button>
              </div>
            </div>
          </div>

          <div class="py-3" :class="{ 'card-body': !dialog }">
            <div class="table-responsive">
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <thead>
                  <tr class="fw-bolder text-muted">
                    <th class="w-25px" style="display: none">
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                        />
                      </div>
                    </th>
                    <th class="min-w-200px">Usuários ({{ itensTotal }})</th>
                    <th class="mw-200px min-w-200px">Tipo de usuário</th>
                    <th class="mw-200px min-w-200px" v-if="colunaNuvem">
                      Nuvem
                    </th>
                    <th class="w-150px text-center">Status</th>
                    <th class="w-40px text-end">Opções</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in itens" :key="item._id" :item="item">
                    <td style="display: none">
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input widget-9-check"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-45px me-5">
                          <img
                            :src="
                              $funcoes.usuarioFoto(
                                item._id,
                                item.foto == true ? true : false
                              )
                            "
                            alt=""
                          />
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <span
                            class="text-dark fw-bolder text-hover-primary fs-6"
                            style="cursor: pointer"
                            @click="editarItem(item._id)"
                          >
                            {{ item.nome }}
                          </span>
                          <span
                            class="text-muted fw-bold text-muted d-block fs-7"
                            >{{ item.nomeUsuario }}</span
                          >
                        </div>
                      </div>
                    </td>

                    <td>
                      <span class="text-dark fw-bolder d-block fs-6">
                        {{
                          item.tipoUsuario
                            ? $funcoes.tiposUsuario()[item.tipoUsuario].nome
                            : "Admin"
                        }}</span
                      >
                      <span class="text-muted fw-bold d-block fs-7">
                        {{ item.email }}</span
                      >
                    </td>

                    <td v-if="colunaNuvem">
                      <span class="text-dark fw-bolder d-block fs-6">
                        {{ item.cliente ? item.cliente.nome : "Admin" }}
                      </span>
                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.nuvem ? item.nuvem.nome : "" }}</span
                      >
                    </td>

                    <td class="text-center">
                      <span
                        class="badge badge-light-success"
                        v-if="item.status == true"
                        >Ativo</span
                      >
                      <span
                        class="badge badge-light-danger"
                        v-if="item.status == false"
                        >Inativo</span
                      >
                    </td>

                    <td>
                      <div class="d-flex justify-content-end flex-shrink-0">
                        <button
                          class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                          v-if="1 == 2"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="currentColor"
                              ></path>
                              <path
                                opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="currentColor"
                              ></path>
                              <path
                                opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </button>
                        <button
                          class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Editar"
                          @click="editarItem(item._id)"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Paginacao
              ref="paginacao"
              :total="itensTotal"
              :limit="20"
              @change-pagina="carregarItens()"
            />
          </div>
        </div>
      </div>
    </div>

    <UsuarioEdit
      :id="'formUsuario'"
      ref="formUsuario"
      view="modal"
      :sysadmin="sysadmin"
      @save-item="saveItem()"
      @change-foto="carregarItens()"
      @dialog-on="
        if (this.dialog) {
          $emit('dialog-on');
        }
      "
      @dialog-off="
        if (this.dialog) {
          $emit('dialog-off');
        }
      "
    />

    <UsuarioSelectDialog
      ref="usuarioSelectDialog"
      @dialog-on="
        if (this.dialog) {
          $emit('dialog-on');
        }
      "
      @dialog-off="
        if (this.dialog) {
          $emit('dialog-off');
        }
      "
    />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

//import Combo from "../../components/ComponentCombo.vue";
import Paginacao from "../components/ComponentPaginacao.vue";
import UsuarioEdit from "./UsuarioEdit.vue";
import UsuarioSelectDialog from "./UsuarioSelectDialog.vue";

//Combo, Paginacao,

export default {
  components: { UsuarioEdit, UsuarioSelectDialog, Paginacao },
  props: {
    dialog: { type: Boolean, default: false },
    buttonNovo: { type: Boolean, default: true },
    buttonSelecionar: { type: Boolean, default: false },
    colunaNuvem: { type: Boolean, default: false },
    sysadmin: { type: Boolean, default: false },
  },

  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      filtroTexto: "",
      filtroTipoUsuario: "",
      filtroCliente: "",
      filtroNuvem: "",
      ordem: "status_DESC,tipoUsuario_ASC,nome_ASC",
      itens: [],
      estatisticas: {
        espacoTotal: 0,
        espacoUtilizado: 0,
        usuariosTotal: 0,
        usuariosUtilizado: 0,
      },
    };
  },

  mounted() {
    if (!this.dialog) {
      this.carregarItens();
    }
  },

  methods: {
    async saveItem() {
      if (this.dialog) {
        this.$emit("dialog-off");
      }

      this.carregarItens();
    },
    async carregarItens() {
      let filtros = "";

      filtros += "?_limit=" + 20;

      filtros += "&_page=" + this.$refs.paginacao.pagina;

      if (this.sysadmin) {
        this.filtroTipoUsuario = "admin";
      } else {
        this.filtroTipoUsuario = "adminNuvem,usuarioNuvem";
      }

      if (this.colunaNuvem) {
        this.ordem = "nome_ASC";
      }

      if (this.filtroTipoUsuario !== "") {
        filtros += "&tipoUsuario=" + this.filtroTipoUsuario;
      }

      if (this.filtroNuvem !== "") {
        filtros += "&nuvem=" + this.filtroNuvem;
      }

      if (this.filtroCliente !== "") {
        filtros += "&cliente=" + this.filtroCliente;
      }

      if (this.filtroTexto !== "") {
        filtros += "&_all=" + this.filtroTexto;
      }

      filtros += "&_ordem=" + this.ordem;

      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/usuario" + filtros);
      api.valid(response.data, this.$router);

      this.itens = response.data.itens;
      this.itensTotal = response.data.total;

      setTimeout(function () {
        window.KTApp.initBootstrapTooltips();
      }, 200);
    },
    editarItem(id) {
      if (this.dialog) {
        this.$emit("dialog-on");
      }

      this.$refs.formUsuario.carregarItem(id);
      this.$refs.formUsuario.abrir();
    },
    async novoItem() {
      if (this.dialog) {
        this.$emit("dialog-on");
      }

      await this.$refs.formUsuario.novo();

      if (this.filtroCliente !== "" && this.filtroNuvem !== "") {
        this.$refs.formUsuario.item.cliente = this.filtroCliente;
        this.$refs.formUsuario.item.nuvem = this.filtroNuvem;
      }

      this.$refs.formUsuario.abrir();
    },
    selecionaUsuarios() {
      if (this.dialog) {
        this.$emit("dialog-on");
      }
      //this.$refs.usuarioSelectDialog.carregarItem(id);
      this.$refs.usuarioSelectDialog.abrir(this.filtroCliente);
    },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        //obj.$refs.paginacao.pagina = 1;
        obj.carregarItens();
      }, 300);
    },
  },
};
</script>

<style>
</style>
