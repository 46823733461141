<template>
  <div class="row" v-if="this.totalPaginas > 1">
    <div
      class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
      v-if="!center"
    ></div>
    <div
      class="col-sm-12 d-flex align-items-center justify-content-center"
      :class="{
        'col-md-7': !center,
        'col-md-12': center,
        'justify-content-md-end': !center,
      }"
    >
      <div class="dataTables_paginate paging_simple_numbers">
        <ul class="pagination">
          <li
            class="paginate_button page-item previous"
            :class="{ disabled: pagina == 1 }"
          >
            <button @click="alterarPagina(pagina - 1)" class="page-link">
              <i class="previous"></i>
            </button>
          </li>

          <div v-for="botao in botoes" :key="botao" :item="botao">
            <li
              class="paginate_button page-item"
              :class="{ active: botao == pagina }"
            >
              <button @click="alterarPagina(botao)" class="page-link ms-1 me-1">
                {{ botao }}
              </button>
            </li>
          </div>

          <li
            class="paginate_button page-item next"
            :class="{ disabled: pagina == totalPaginas }"
          >
            <button @click="alterarPagina(pagina + 1)" class="page-link">
              <i class="next"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: { type: Number, default: 0 },
    limit: { type: Number, default: 1 },
    center: { type: Boolean, default: false },
  },
  data() {
    return {
      pagina: 1,
    };
  },
  computed: {
    botoes() {
      var arrBotoes = [];

      var inicio = 1;
      var fim = Math.min(10, this.totalPaginas);

      if (this.pagina > 5) {
        inicio = this.pagina - 3;
        fim = Math.min(this.pagina + 5, this.totalPaginas);
      }

      for (var indice = inicio; indice <= fim; indice++) {
        arrBotoes.push(indice);
      }

      return arrBotoes;
    },
    totalPaginas() {
      return this.total % this.limit === 0
        ? Math.floor(this.total / this.limit)
        : Math.floor(this.total / this.limit) + 1;
    },
    indiceCalculoPagina() {
      return Math.max(1, this.pagina > 4 ? 4 : this.pagina);
    },
  },
  mounted() {},
  methods: {
    alterarPagina(novaPagina) {
      this.pagina = novaPagina;
      this.$emit("change-pagina");
    },
  },
};
</script>

<style>
</style>
