<template>
  <div>
    <div class="mt-1 d-flex justify-content-center">
      <div
        class="image-input image-input-outline mh-125px"
        data-kt-image-input="true"
        style="background-image: url('/assets/media/svg/avatars/blank.svg')"
      >
        <div
          :id="customId"
          class="image-input-wrapper w-125px h-125px"
          style="
            background-position: center center;
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
          "
        ></div>
        <label
          class="
            btn btn-icon btn-circle btn-active-color-primary
            w-25px
            h-25px
            bg-body
            shadow
          "
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip2"
          title=""
        >
          <i class="bi bi-pencil-fill fs-7"></i>
          <input
            type="file"
            @change="onFileChange"
            accept=".png, .jpg, .jpeg"
          />
        </label>
        <span
          class="btn btn-icon btn-circle btn-success w-25px h-25px shadow"
          data-kt-image-input-action=""
          @click="false"
          style="position: absolute; left: 100%; top: 100%; cursor: default"
          v-if="concluido"
        >
          <i class="bi bi-check-circle-fill fs-2"></i>
        </span>
      </div>
    </div>
    <div class="mt-5 text-center" v-if="this.selectedFile">
      <div class="fs-6 fw-bold text-danger" v-if="erroMensagem !== ''">
        {{ erroMensagem }}
      </div>

      <button
        type="button"
        class="
          btn
          btn-outline
          btn-outline-dashed
          btn-outline-primary
          btn-active-light-primary
        "
        :data-kt-indicator="enviando ? 'on' : 'off'"
        @click="onUploadFile"
        v-if="erroMensagem == ''"
      >
        <span class="indicator-label" v-if="!uploadAutomatico">
          Enviar foto
        </span>
        <span class="indicator-progress">
          Aguarde...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";

export default {
  props: {
    uploadAutomatico: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedFile: "",
      customId: "",
      enviando: false,
      concluido: false,
      urlFoto: "",
      usuario: "",
      erroMensagem: "",
    };
  },
  computed: {},

  mounted() {
    this.customId = "ComponentUploadFoto_" + this.$funcoes.guidGenerator();
  },
  methods: {
    refreshUrlFoto(usuario, foto) {
      //* Armazena o usuario definido
      this.usuario = usuario;

      //* Carrega a foto atual do usuario
      this.urlFoto = this.$funcoes.usuarioFoto(usuario, foto);
      var imageData = document.getElementById(this.customId);
      imageData.style.backgroundImage = "url(" + this.urlFoto + ")";

      //* Limpa o status de envio
      this.concluido = false;
      this.enviando = false;
      this.erroMensagem = "";
    },
    onFileChange(e) {
      var thisCall = this;

      console.log("Arquivos selecionados:" + e.target.files.length);

      //* Verifica se selecionou algum arquivos
      if (e.target.files.length > 0) {
        this.selectedFile = e.target.files[0];

        //* Limpa o status de envio
        this.concluido = false;
        this.enviando = false;
        this.erroMensagem = "";

        //* Verifica se deve efetuar o upload
        if (this.uploadAutomatico) {
          setTimeout(function () {
            thisCall.onUploadFile();
          }, 300);
        }
      }
    },
    onUploadFile() {
      var thisCall = this;

      //* Carrega os dados da sessao
      var authStore = useAuthStore();

      //* Define que esta enviando a imagem
      this.enviando = true;

      //* Inicializa os dados a serem enviados
      const formData = new FormData();
      //formData.append("file", this.selectedFile);

      //* Carrega o conteudo da imagem selecionada
      var imageData = document.getElementById(this.customId);
      formData.append(
        "file",
        this.dataURIToBlob(
          imageData.style.backgroundImage.slice(4, -1).replace(/"/g, "")
        ),
        this.selectedFile
      );

      //* Verifica se esta enviando um usuario diferente do logado
      if (this.usuario !== "") {
        formData.append("usuario", this.usuario);
      }

      //* Envia a foto
      api
        .post(process.env.VUE_APP_URL_API + "/upload/usuario/foto", formData)
        .then(function () {
          //* Caso seja o proprio usuario alterando a foto
          if (
            authStore.sessao.usuario &&
            thisCall.usuario == authStore.sessao.usuario._id
          ) {
            //* Sinaliza que foi definida uma foto
            authStore.fotoAlterada();
          }

          //* Sinaliza que a foto foi alterada
          thisCall.$emit("change-foto");

          setTimeout(function () {
            thisCall.enviando = false;
            thisCall.selectedFile = "";
            thisCall.concluido = true;
          }, 1000);
        })
        .catch(function (err) {
          //* Define a mensagem de erro
          if (err.response?.data?.mensagem) {
            thisCall.erroMensagem = err.response?.data?.mensagem;
          }
        });
    },
    dataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
  },
};
</script>

<style scoped>
</style>
