<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-11 col-sm-12 col-xs-12">
      <PlanoList />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PlanoList from "../../../../_shared/features/PlanoList.vue";

export default {
  name: "PlanosAdmin",
  components: { PlanoList },
  data() {
    return {};
  },
  methods: {},
};
</script>
