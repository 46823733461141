import { defineStore } from "pinia";

//import { fetchWrapper, router } from '@/helpers';
//import { router } from "@/router/index.js";
import { api } from "@/plugins/api.js";

//const baseUrl = `${import.meta.env.VITE_API_URL}/users`;

export const useAuthStore = defineStore({
	id: "auth",
	state: () => ({
		sessao: JSON.parse(localStorage.getItem("ckdCch")),
		returnUrl: null
	}),
	actions: {
		async entrar(nomeUsuario, senha) {
			const apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/sessao", { nomeUsuario, senha });

			if (apiRequest.data.erro) {
				return { erro: true, erros: apiRequest.data.erros };
			} else {
				// eslint-disable-next-line no-lonely-if
				if (apiRequest.data.sessao && apiRequest.data.sessao.key) {
					localStorage.setItem("ckd", apiRequest.data.sessao.key);
					localStorage.setItem("ckdCch", JSON.stringify(apiRequest.data.sessao));

					window.location = "/admin/home";

					return { erro: false };
				}

				return { erro: true };
			}

			/*
            const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });

            // update pinia state
            this.user = user;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/');*/
		},
		fotoAlterada() {
			this.sessao.usuario.foto = true;
			localStorage.setItem("ckdCch", JSON.stringify(this.sessao));
		},
		sair() {
			this.sessao = null;
			localStorage.removeItem("ckd");
			localStorage.removeItem("ckdCch");
			window.location = "/admin/login";
			//router.push("/admin/login");
		}
	}
});
