<template>
  <div class="row justify-content-center" style="margin-top: 10px">
    <div class="text-center col-12">
      <Diretorio tipo="remoto" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Diretorio from "../../../../_shared/components/ComponentDiretorio.vue";

export default {
  name: "HomeSite",
  components: { Diretorio },
  data: function () {
    return {};
  },
};
</script>
