<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormGrupo'"
        ref="dialogFormGrupo"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div class="d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
          </div>
        </div>
        <div>
          <div v-if="item._id == ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Novo grupo</h2>
          </div>

          <div v-if="item._id !== ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Editando grupo</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <div>
            <div class="fv-row mb-3">
              <label class="fs-6 fw-bold mb-2">Nome</label>

              <input
                v-model="item.nome"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.nome.status }"
                type="text"
                @keyup="enterSalvar"
                autofocus="true"
                ref="nome"
                autocomplete="off"
              />
              <div v-if="itemErros.nome.status" class="invalid-feedback">
                {{ itemErros.nome.mensagem }}
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table
              class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
            >
              <thead>
                <tr class="fw-bolder text-dark">
                  <th class="text-start">
                    Usuários ({{ item.usuarios ? item.usuarios.length : 0 }})
                  </th>
                  <th class="text-end">
                    <span
                      class="badge cursor-pointer badge-dark"
                      @click="adicionarUsuario"
                      >+ Adicionar</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(usuario, indexUsuario) in item.usuarios"
                  :key="usuario"
                  :item="usuario"
                >
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-45px me-5">
                        <img
                          :src="
                            $funcoes.usuarioFoto(
                              usuario,
                              usuariosDados[usuario] &&
                                usuariosDados[usuario].foto == true
                                ? true
                                : false
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="d-flex justify-content-start flex-column">
                        <span class="text-start text-dark fw-bolder fs-6">{{
                          !usuariosDados[usuario]
                            ? usuario
                            : usuariosDados[usuario].nome
                        }}</span
                        ><span
                          class="text-start text-muted fw-bold text-muted d-block fs-7"
                          >{{
                            !usuariosDados[usuario]
                              ? usuario
                              : usuariosDados[usuario].email
                          }}</span
                        >
                      </div>
                    </div>
                  </td>
                  <td class="text-end">
                    <button
                      class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title="Excluir"
                      aria-label="Excluir"
                      @click="removerUsuario(indexUsuario)"
                    >
                      <span class="svg-icon svg-icon-3"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          /></svg
                      ></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              class="text-center"
              :class="{
                'd-none': !item.usuarios || item.usuarios.length > 0,
              }"
            >
              Nenhum usuario
            </p>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
    <UsuarioSelectDialog
      ref="dialogUsuarios"
      @cancelar="adicionarUsuarioRetorno(null)"
      @selecionar="adicionarUsuarioRetorno"
    />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Combo from "../components/ComponentCombo.vue";
import Dialog from "../components/ComponentDialog.vue";
import UsuarioSelectDialog from "./UsuarioSelectDialog.vue";

export default {
  components: { Combo, Dialog, UsuarioSelectDialog },
  props: { view: { type: String, default: "modal" } },

  data() {
    return {
      item: {
        _id: "",
        nome: "",
        usuarios: "",
      },
      itemErros: {
        nome: { status: false, mensagem: "" },
      },
      usuariosDados: {},
      mensagem: "",
      carregando: false,
    };
  },

  mounted() {},

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = this.item;

      if (this.item._id === "") {
        apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/grupo", body);
      } else {
        apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/grupo/" + this.item._id, body);
      }

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else if (this.item._id === "") {
        this.carregarItem(apiRequest.data._id);
      }

      this.$emit("save-item");

      this.fechar();
    },
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogFormGrupo.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nome"].focus();
      }, 500);
    },
    fechar() {
      this.$refs.dialogFormGrupo.fechar();
      this.$emit("dialog-off");
    },

    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";

      this.item._id = "";
      this.item.nome = "";
      this.item.usuarios = [];

      //* Carrega os dados dos usuarios
      this.carregarUsuariosDados();

      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Limpa o objeto
      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/grupo/" + id);

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];
        this.item._id = itemEdit._id;
        this.item.nome = itemEdit.nome;
        this.item.usuarios = itemEdit.usuarios;
      }
    },
    async carregarUsuariosDados() {
      this.usuariosDados = await api.getItensObjectId(process.env.VUE_APP_URL_API + "/admin/usuario");      
    },
    async adicionarUsuario() {
      this.$refs.dialogFormGrupo.fechar();
      this.$refs.dialogUsuarios.abrir(
        this.item.usuarios.reduce((a, v) => ({ ...a, [v]: v }), {})
      );
    },
    async removerUsuario(itemIndex) {
      this.item.usuarios.splice(itemIndex, 1);
    },
    async adicionarUsuarioRetorno(itensSelecionados) {
      if (itensSelecionados !== null) {
        //* Insere usuarios selecionados
        this.item.usuarios.push(...Object.keys(itensSelecionados));
      }

      //* Abre a jenela novamente
      this.$refs.dialogFormGrupo.abrir();
    },
  },
};
</script>

<style>
</style>
