<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-11 col-sm-12 col-xs-12">
      <SessaoList :colunaNuvem="true" :sysadmin="true" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SessaoList from "../../../../_shared/features/SessaoList.vue";

export default {
  name: "SessoesAdmin",
  components: { SessaoList },
  data() {
    return {};
  },
  methods: {}
};
</script>
