import { createRouter, createWebHistory } from "vue-router";

//import Vue from "vue";
//import VueRouter from "vue-router";

import Login from "../views/ViewLogin.vue";

import HomeSite from "../views/site/HomeSite.vue";
import CompartilhamentosSite from "../views/site/CompartilhamentosSite.vue";
import GruposSite from "../views/site/GruposSite.vue";
import UsuariosSite from "../views/site/UsuariosSite.vue";

import HomeAdmin from "../views/admin/HomeAdmin.vue";
import PlanosAdmin from "../views/admin/PlanosAdmin.vue";
import ClientesAdmin from "../views/admin/ClientesAdmin.vue";
import NuvensAdmin from "../views/admin/NuvensAdmin.vue";
import GruposAdmin from "../views/admin/GruposAdmin.vue";
import UsuariosAdmin from "../views/admin/UsuariosAdmin.vue";
import AdministradoresAdmin from "../views/admin/AdministradoresAdmin.vue";
import SessoesAdmin from "../views/admin/SessoesAdmin.vue";
import StoragesAdmin from "../views/admin/StoragesAdmin.vue";

import { useAuthStore } from "@/stores/auth.js";

//Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "homeAdmin" }
  },
  {
    path: "/site/login",
    name: "loginSite",
    component: Login
  },
  {
    path: "/site",
    redirect: "/site/home"
  },
  {
    path: "/site/home",
    name: "homeSite",
    component: HomeSite
  },
  {
    path: "/site/compartilhamentos",
    name: "compartilhamentosSite",
    component: CompartilhamentosSite
  },
  {
    path: "/site/grupos",
    name: "gruposSite",
    component: GruposSite
  },
  {
    path: "/site/usuarios",
    name: "usuariosSite",
    component: UsuariosSite
  },

  {
    path: "/admin/login",
    name: "loginAdmin",
    component: Login
  },
  {
    path: "/admin",
    redirect: { name: "homeAdmin" }
  },
  {
    path: "/admin/home",
    name: "homeAdmin",
    component: HomeAdmin
  },
  {
    path: "/admin/planos",
    name: "planosAdmin",
    components: { default: PlanosAdmin }
  },
  {
    path: "/admin/clientes",
    name: "clientesAdmin",
    component: ClientesAdmin
  },
  {
    path: "/admin/nuvens",
    name: "nuvensAdmin",
    component: NuvensAdmin
  },
  {
    path: "/admin/grupos",
    name: "gruposAdmin",
    component: GruposAdmin
  },
  {
    path: "/admin/usuarios",
    name: "usuariosAdmin",
    component: UsuariosAdmin
  },
  {
    path: "/admin/administradores",
    name: "administradoresAdmin",
    component: AdministradoresAdmin
  },
  {
    path: "/admin/sessoes",
    name: "sessoesAdmin",
    component: SessoesAdmin
  },
  {
    path: "/admin/storages",
    name: "storagesAdmin",
    component: StoragesAdmin
  }
];

const router = createRouter({ history: createWebHistory(), routes });

router.beforeEach(async (to) => {
  const publicPages = ["/admin/login", "/app/login", "/site/login"];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.sessao) {
    auth.returnUrl = to.fullPath;
    return "/admin/login";
  }
});

export default router;

/*

 {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about"  "../views/AboutView.vue"),
    },

*/
